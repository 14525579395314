@use 'colors';

.mat-mdc-form-field-type-eca-time.mat-mdc-form-field.mat-form-field-appearance-outline {
    // overrides eca-form-column 100%
    width: min-content;
    .mat-mdc-form-field-infix {
      width: max-content;
      max-width: 124px;
    }
}

