/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@use 'node_modules/@drc-eca/eca-components-lib/src/styles/theme' as eca;

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;1,400;1,600&display=swap');

// Define the light theme.
$primary: mat.m2-define-palette(eca.$drc-blue-palette);
$accent: mat.m2-define-palette(eca.$drc-medium-blue-palette, 200, 100, 800);

$theme: mat.m2-define-light-theme((
  color: (primary: $primary, accent: $accent)
));
@include mat.all-component-themes($theme);

/* This makes the subtitles in the user agreement modal semi-bold.
   The reason this setting is here is because the modal content is inserted from outside and the component is not aware of the classes that exist inside.
*/
/* TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version. */
/* TODO Update when Components Library generic dialogs are updated */
.mat-dialog-title-content h3 {
  font-weight: 600!important;
}

@import 'node_modules/@drc-eca/eca-components-lib/styles.scss';

eca-pillar-box {
  .content-column {
    max-width: 650px;
    margin-bottom: initial;
    margin-top: initial;
    margin-left: auto;
    margin-right: auto;
  }
}
