@use 'colors';
@use 'theme';

// used primarily in eca-header and menus
$drc-menu-dark: #343C48; // 'steel'
$drc-menu-light: #BFC2C5; // 'silver'

/* Prevent menus to be clickable when a dialog is open (see IPN-186) */
.cdk-overlay-container {
  z-index: 9999 !important;
}

eca-header, [class^="cdk-"] {
  // some apps set -webkit-font-smoothing: antialiased: which makes the header render incorrectly.
  // Auto is preferred for the best results in all cases.
  -webkit-font-smoothing: auto;

  * {
    box-sizing: border-box;
  }
}

eca-header * {
  // main purpose is to prevent an element (site selector) from not fitting within the viewport at 400% zoom
  max-width: 100%;
  .mat-mdc-progress-spinner, .mat-mdc-progress-spinner * {
    max-width: none;
    &.mdc-circular-progress {
      --mdc-circular-progress-active-indicator-color: var(--drc-insight-gold);

      circle {
        stroke: var(--drc-insight-gold) !important;
      }
    }
  }
}

.header-menu-secondary {
  button {
    cursor: pointer;
  }
}

$large-icon-size: 40px;

eca-header-base {

  nav .nav-link, nav .nav-menu-link {
    font-size: 16px;
    color: colors.$drc-white;
    text-transform: uppercase;
    font-weight: 500;
    padding: 8px 6px 8px 6px;
    margin: 8px 4px 8px 4px;
    cursor: pointer;
    line-height: 20px;

    &:not(:focus) {
      // previously this was applied regardless of focus
      text-decoration: none;
    }

    &[aria-expanded="true"] {
      text-decoration: underline;
    }
    &:hover {
      text-decoration: underline;
      color: theme.$drc-link-color;
    }
  }

  .nav-menu-link:not(.single-link):after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 8px;
    vertical-align: 3px;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid rgba(0, 0, 0, 0);
    border-bottom: 0;
    border-left: .3em solid rgba(0, 0, 0, 0);
  }

  #eca-user-profile-menu-link {
    padding: 10px;
    height: inherit;
  }
  button.mat-mdc-icon-button{
    display: block;
    height: 24px;
    width: 24px;
    line-height: 24px;
    border-radius: 0;
    padding: 0 !important;
    box-sizing: content-box;

    .mat-icon {
      vertical-align: middle;
    }
  }


  &.large-icon button.mdc-icon-button,
  &.large-icon button.mat-mdc-menu-trigger {

    &#eca-user-profile-menu-link {
      padding: 4px;
    }
    .user-profile-link {
      font-size: 16px;
      line-height: 2;
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
    }

    &.mat-mdc-icon-button {
      height: $large-icon-size !important;
      width: $large-icon-size !important;
      line-height: $large-icon-size !important;
      border-radius: 0;
      padding: 0 !important;

      .mat-icon {
        display: block;
        line-height: $large-icon-size !important;
        font-size: 36px;
        height: $large-icon-size;
        width: $large-icon-size;
      }
    }
  }

  // default the color to white
  button:not([id^=eca-site-selector-open-button]) mat-icon {
    color: colors.$drc-white;
  }

  eca-site-selector {
    button.mat-mdc-button .mdc-button__label {
      height: 100%;
      overflow: hidden;
    }
  }

  .user-profile-link {
    background-color: colors.$drc-white;
  }

  &.silver-text-color {
    button mat-icon {
      color: $drc-menu-light;
    }

    .user-profile-link {
      background-color: $drc-menu-light;
    }
  }

  &.max-width-1440 {
    .drc-header-contents {
      max-width: 1440px;
    }
  }
}
