@use 'colors';
@use 'form-theme';

$box-shadow-border:
  inset 0 -2px 0 colors.$drc-accent-blue,
  inset 0 2px 0 colors.$drc-accent-blue,
  inset -2px 0 0 colors.$drc-white,
  inset 2px 0 0 colors.$drc-white;

$box-shadow-border-inverse-colors:
  inset 0 -2px 0 colors.$drc-amber,
  inset 0 2px 0 colors.$drc-amber,
  inset -2px 0 0 transparent,
  inset 2px 0 0 transparent;

@mixin focus-position {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000;
  background-color: transparent;
  box-sizing: content-box !important;
}

@mixin focus-outline {
  @include focus-position;
  top: -1px;
  left: -2px;
  padding: 2px;
  box-shadow: 0 8px 10px -6px var(--drc-dark-grey-15), $box-shadow-border;
  border-radius: 4px;
}

@mixin focus-outline-inverse-colors {
  @include focus-position;
  top: -1px;
  left: -2px;
  padding: 2px;
  box-shadow: 0 8px 10px -6px var(--drc-dark-grey-15), $box-shadow-border-inverse-colors;
  border-radius: 4px;
}

@mixin focus-outline-toggle {
  @include focus-outline;
  top: -12px;
  left: -8px;
  padding: 12px 8px;
}

@mixin focus-outline-checkbox {
  @include focus-outline;
  top: 0;
  left: 0;
  padding: 0;
  z-index: 0;
}

@mixin focus-outline-radio {
  @include focus-outline;
  top: -2px;
  left: -2px;
  z-index: 0; // necessary so that the radio is clickable??
}

@mixin focus-outline-card {
  @include focus-position;
  height: calc(100% + 2px); // accounts for 2px of box shadow on the card
  top: 0;
  left: 0;
  box-shadow: 0 20px 25px -5px colors.$drc-dark-grey-15,
    0 8px 10px -6px colors.$drc-dark-grey-15,
    inset 0px -2px 0px colors.$drc-accent-blue,
    inset 0px 2px 0px colors.$drc-accent-blue;
  border-radius: 4px;
}

@mixin focus-outline-left-nav {
  @include focus-position;
  top: 0;
  left: 0;
  box-shadow: 0 8px 10px -6px var(--drc-dark-grey-15), $box-shadow-border;
  border: none;
  padding: 0;
}
