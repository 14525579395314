@use 'focus-mixin';
@use 'shadows';

mat-card.mat-mdc-card:not([class*=mat-elevation-z]) {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);

  &.shadow-1 {
    @include shadows.shadow-1;
  }

  &.shadow-2 {
    @include shadows.shadow-2;
  }

  &.shadow-3 {
    @include shadows.shadow-3;
  }

  &.shadow-4 {
    @include shadows.shadow-4;
  }

  &.shadow-5 {
    @include shadows.shadow-5;
  }
}


button.eca-card {
  text-align: left;
  padding: 0;
  border: 0;
  cursor: pointer;
}
.eca-card {
  max-width: 320px;

  &.focus-visible, &:hover {
    .mat-mdc-card::before {
      @include focus-mixin.focus-outline-card;
    }
    & {
      outline: none;
    }
  }
}

mat-card.mat-mdc-card{
  padding: 16px;

  .mat-mdc-card-header {
    padding: 0;
  }
  .mat-mdc-card-content {
    padding: 0;
  }

  eca-icon {
    height: 48px;
    width: 48px;
  }
  mat-card-title.mat-mdc-card-title {
    color: var(--drc-blue);
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0;
  }

  .mat-mdc-card-subtitle {
    color: var(--drc-black);
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }

  .mat-mdc-card-content {

  }
}
