$alert-color: #354052;
$alert-positive-bg: #dff0d0;
$alert-negative-bg: #f5dbd9;
$alert-warning-bg: #feecb5;
$alert-info-bg: #e1f1f6;

$alert-positive-border: #84c92d;
$alert-negative-border: #ebafa6;
$alert-warning-border: #efd603;
$alert-info-border: #89cbdf;

$alert-positive-icon: #1bb934;
$alert-negative-icon: #c92100;
$alert-warning-icon: #efd603;
$alert-info-icon: #89cbdf;

mat-snack-bar-container.mat-mdc-snack-bar-container {

  --mdc-snackbar-supporting-text-color: var(--drc-black);
  --mdc-snackbar-supporting-text-weight: 600;
  --mdc-snackbar-supporting-text-size: 16px;
  --mdc-snackbar-supporting-text-line-height: 24px;

  position: relative;
  left: 0;
  top: 58px;
  width: 100%;
  min-width: 100%;
  color: $alert-color;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  min-height: 24px;

  eca-snackbar-alert {
    display: flex;
  }

  .mdc-snackbar__surface {
    max-width: 100%;
    border: 1px solid;
    padding: 0 8px;
    min-width: 200px;
  }

  .snack-icon,
  .snack-icon-close {
    width: 24px;
    height: 24px;
    min-width: 24px;
    font-size: 18px;
    line-height: 24px;
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0;
  }

  .message {
    vertical-align: middle;
  }

  a {
    margin: 0 4px;
  }

  .snack-icon-close {
    margin-left: 10px;
    cursor: pointer;
  }

  &.mat-snack-positive {
    .mdc-snackbar__surface {
      background-color: $alert-positive-bg;
      border-color: $alert-positive-border;
    }

    .snack-icon {
      color: $alert-positive-icon;
    }
  }

  &.mat-snack-negative {
    .mdc-snackbar__surface {
      background-color: $alert-negative-bg;
      border-color: $alert-negative-border;
    }

    .snack-icon {
      color: $alert-negative-icon;
    }
  }

  &.mat-snack-warning {
    .mdc-snackbar__surface {
      background-color: $alert-warning-bg;
      border-color: $alert-warning-border;
    }

    .snack-icon {
      color: $alert-warning-icon;
    }
  }

  &.mat-snack-info {
    .mdc-snackbar__surface {
      background-color: $alert-info-bg;
      border-color: $alert-info-border;
    }

    .snack-icon {
      color: $alert-info-icon;
    }
  }

  .alert-link{
    color: #4a90e2;
    text-decoration: underline;
    margin: 0 6px;
  }
}
