@mixin shadow-1 {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24), 0 0 2px rgba(0, 0, 0, 0.12);
}

@mixin shadow-2 {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24), 0 0 4px rgba(0, 0, 0, 0.12);
}

@mixin shadow-3 {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.24), 0 0 8px rgba(0, 0, 0, 0.12);
}

@mixin shadow-4 {
  box-shadow: 0 16px 16px rgba(0, 0, 0, 0.24), 0 0 16px rgba(0, 0, 0, 0.18);
}

@mixin shadow-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.32), 0 15px 12px rgba(0, 0, 0, 0.22);
}
