@use 'colors';
@use 'include-media' as eca;

$input-selected-color: colors.$drc-black;
$input-disabled-color: colors.$drc-medium-grey;

$checkbox-radio-border: colors.$drc-black;

// if this border-width changes there are other styles that will need updating
// usage of this variable has changed since hover/focus of most elements are now a box-shadow not a border
// focus-mixin.scss - padding and/or border
// button.scss - line-height and padding
// datepicker.scss - padding
// form-field.scss - mat-form-field-suffix top position
$hover-focus-border-width: 3px;

/**
  LABELS
 */
@mixin base-label {
  padding-left: 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 34px;
  color: colors.$drc-black;
}

@mixin inactive-label() {
  @include base-label;
}

@mixin disabled-label() {
  @include base-label;
}

@mixin selected-label() {
  @include base-label;
}

@mixin selected-disabled-label() {
  @include base-label;
}

/**
  MENU overlays
  used for dropdowns, multiselect, autocomplete
 */
$form-select-panel-bg: colors.$drc-white;
$form-select-panel-hover-bg: colors.$drc-accent-blue;
$form-select-panel-hover-color: colors.$drc-black;

@mixin select-options-panel() {

  & {
    padding-top: 8px;
    padding-bottom: 8px;
    // Allow 10 choices to be displayed before scrolling
    // (44px * 10) = 440 + 4px borders + 16px padding
    max-height: 460px !important;
    border-radius: 4px;
    border: 2px solid colors.$drc-black;
  }

  // on small screens reduce the height so all options are visible
  @include eca.media('height<lg') {
    max-height: 256px !important;
  }
  @include eca.media('height>=lg', 'height<xl') {
    max-height: 360px !important;
  }

  &:not([class*=mat-elevation-z]).mat-primary {
    box-shadow: 0 25px 50px -4px rgba(38, 45, 57, 0.25);
    border-radius: 4px;
  }

  .mat-mdc-optgroup {
    .mat-mdc-optgroup-label {
      color: colors.$drc-black;
      height: 44px;
      padding-left: 24px;
    }

    .mat-mdc-option {
      padding-left: 40px;
    }
  }

  .mat-mdc-option {
    min-height: 44px;
    line-height: 34px;
    color: colors.$drc-black;
    background-color: $form-select-panel-bg;
    margin-left: 8px;
    margin-right: 8px;

    .mat-icon {
      margin-right: 10px;
    }
    .mat-pseudo-checkbox.mat-pseudo-checkbox-minimal {
      display: none;
    }
  }
  .mat-mdc-option[disabled] {
    color: colors.$drc-medium-grey;
  }

  .mat-mdc-option:hover:not([disabled]),
  .mat-mdc-option:focus:not([disabled]),
  .mat-mdc-option.mat-mdc-option-active:not([disabled]) {
    box-shadow: 0 8px 10px -6px
      var(--drc-dark-grey-15),
      inset 0px -2px 0px $form-select-panel-hover-bg,
      inset 0px 2px 0px $form-select-panel-hover-bg;
    border-radius: 4px;
    z-index: 1;
  }

  .mat-mdc-option.mat-mdc-selected {
    font-weight: 600;
    background: rgba(0, 0, 0, 0);
  }
}
