@use '@angular/material' as mat;
@use 'include-media' as eca;
@use 'colors';
@use "sass:math";
@use "sass:map";

$dialog-border: #D0D0D0;
$dialog-padding: 24px;
$dialog-actions-bg: #FAFBFC;
$dialog-margin: -$dialog-padding;
$dialog-backdrop-bg: rgba(0, 0, 0, .5);

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    height: 10px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #00000035;
  }
}

.mat-dialog-tab-content,
.mat-dialog-title-content > .scroll-container {
  display: block;
  padding: math.div($dialog-padding, 2);
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include eca.media('>=sm', 'height>=lg') {
    padding: $dialog-padding;
  }

  @include scrollbar();
}

.cdk-overlay-container {

  .cdk-overlay-dark-backdrop {
    background: $dialog-backdrop-bg;
  }

  // on mobile expand to 100%
  @include eca.media('<=sm') {
    .cdk-overlay-pane {
      // needs to override inline style
      max-width: 100vw !important;
    }
  }

  .mat-mdc-dialog-container {
    border: 1px solid $dialog-border;
    position: relative;
    overflow: hidden;

    .mat-mdc-dialog-title {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      padding: 0 math.div($dialog-padding, 2) math.div($dialog-padding, 2) math.div($dialog-padding, 2);

      @include eca.media('>=sm', 'height>=lg') {
        padding: 0 math.div($dialog-padding, 2) math.div($dialog-padding, 2) math.div($dialog-padding, 2);
      }
    }
    .mat-dialog-title-content, mat-tab-body {
      // for small modals we only want to have height: 100%
      height: 100%;

      // for small modals that have scrolling content we need to set the max-height
      // header 58 + footer 61 = 119
      max-height: calc(100vh - 119px);

      // for large modals that have scrolling content we need to set the max-height
      // header 58 + footer 77 = 135
      @include eca.media('height>=lg') {
        max-height: calc(75vh - 135px);
      }

      .scroll-container {
        height: 100%;
        max-height: inherit;
        overflow: auto;
        font-size: 20px;
        line-height: 24px;
        color: #262626;

        &:focus, &.focus-visible, &:focus-visible {
          outline: 3px solid colors.$drc-blue;
          outline-offset: -3px;
        }
      }
    }

    h2.mat-mdc-dialog-title {
      font-size: 20px;
      line-height: 24px;
      color: #262626;
    }
    .mat-tab-body-wrapper,
    .mat-dialog-title-content {

      $config: mat.m2-define-typography-config();

      h2 {
        @include mat.m2-typography-level($config, headline-6);
      }

      h3 {
        @include mat.m2-typography-level($config, subtitle-1);
      }

      h4 {
        @include mat.m2-typography-level($config, body-1);
      }
    }

    .mat-mdc-tab-header {
      padding: 0 20px 0 28px;
    }

    .mat-mdc-dialog-actions {
      padding: 8px 0;
      place-content: center space-between;
      padding-left: math.div($dialog-padding, 2);
      background-color: $dialog-actions-bg;
      border-top: 1px solid $dialog-border;
      min-height: 0;

      .mat-mdc-button {
        @include eca.media('<=sm') {
          &:only-child {
            justify-self: flex-end;
            margin-left: auto;
          }
        }
      }
      button:last-child {
        margin-right: math.div($dialog-padding, 2);
      }

      @include eca.media('>=sm', 'height>=lg') {
        padding: 16px 0;

        .mat-mdc-button {
          margin-left: $dialog-padding;
        }
        button:last-child {
          margin-right: $dialog-padding;
        }
      }

      @include eca.media('<=sm') {
        flex-direction: column;
        align-items: stretch;
        align-content: stretch;

        .mat-mdc-button {
          margin-left: math.div($dialog-padding, 2);
          margin-right: math.div($dialog-padding, 2);
          margin-top: 8px;

          &:first-child {
            margin-top: 0;
          }
        }
        button:last-child {
          margin-right: math.div($dialog-padding, 2);
        }
      }
    }

    .mat-tab-body-content {
      @include scrollbar();
    }

    /* It's not clear if these styles get applied */
    @include eca.media('<=sm', 'height>sm') {
      max-height: 100vh;
      display: flex;

      & > * {
        max-height: 100%;
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;

        // Old style used to ask to wrap modal content in a div
        // New style doesn't require
        & > div:only-child,
        & > eca-dialog {
          max-height: 100%;
          flex: 0 0 100%;
          display: flex;
          flex-direction: column;
        }

        .mat-mdc-dialog-title {
          flex: 0 0 auto;
        }
        .mat-mdc-dialog-actions {
          flex: 0 0 auto;
        }
        .mat-dialog-title-content {
          flex: 1 1 auto;
          overflow: hidden;
          height: initial;
          max-height: initial;
        }
      }
    }
  }

  @include eca.media('height<sm') {
    .mat-mdc-dialog-container {
      .mat-dialog-title-content,
      mat-tab-body {
        max-height: initial;
      }
    }
    .cdk-overlay-pane {
      overflow: auto;
    }
  }
}

/* Top Right Close Button */
.mat-mdc-icon-button.close-dialog {
  position: absolute;
  top: 6px;
  right: 6px;
  z-index: 100;
  border-color: transparent;

  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-icon-button-focus-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;

  @include eca.media('>=sm', 'height>=lg') {
    top: 7px;
    right: 7px;
  }

  &:hover {
    opacity: 1;
  }
}

