
$button-toggle-height: 44px;


.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-group-appearance-standard.mat-button-toggle-group,
.mat-button-toggle-group {
  border-width: 0;
  .mat-button-toggle + .mat-button-toggle {
    .mat-button-toggle-button {
      border-width: 0;
    }
  }
}

@mixin focused {
  &.cdk-keyboard-focused, &.cdk-focused, &:focus, &:hover {
    &:not(.mat-button-toggle-disabled) {
      background-color: transparent;
      border-color: var(--drc-blue) transparent;
      border-radius: 4px;
      // focus style is left & right border removed
      border-left-width: 0 !important;
      margin-left: 2px;
      & + .mat-button-toggle.mat-button-toggle-appearance-standard {
        // effectively this is the right border
        border-left: 2px solid transparent !important;
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 2px;
        border-right-width: 0 !important;
      }
      .mat-button-toggle-focus-overlay {
        opacity: 1;
      }
      &.eca-primary-button-color {
        background-color: var(--drc-blue);
        border-color: var(--drc-white) var(--drc-blue);
      }
    }
  }
}

@mixin checked {
  --mat-standard-button-toggle-selected-state-background-color: var(--drc-white);
  color: var(--drc-blue);
  &.mat-button-toggle-appearance-standard {
    &:not(.cdk-keyboard-focused):not(.cdk-focused):not(:focus):not(:hover):not(.eca-primary-button-color) {
      color: var(--drc-blue);
    }
    &.eca-primary-button-color {
      color: var(--drc-white);
    }
  }
  // this provides the underline effect for selected/checked buttons
  .mat-button-toggle-focus-overlay {
    background-color: var(--drc-white);
    border-color: var(--drc-blue);
    border-width: 4px 0 0 0;
    border-radius: 8px;
    opacity: 1;
    top: 34px;
  }
  &.eca-primary-button-color {
    color: var(--drc-white);
    .mat-button-toggle-focus-overlay {
      background-color: var(--drc-blue);
      border-color: var(--drc-white);
    }
  }
}

.mat-button-toggle-group-appearance-standard {
  background-color: transparent;
  font-weight: 600;
  height: $button-toggle-height;
  letter-spacing: 0.16px;

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: $button-toggle-height - 12px;
    padding: {
      left: 8px;
      right: 8px;
    }
    position: relative;

    &::after {
      content: none;
      position: absolute;
      bottom: 6px;
      left: 8px;
      right: 8px;
      height: 2px;
    }
  }
  .mat-button-toggle {
    background-color: transparent;
    border: 2px solid var(--drc-light-grey);
    padding: 6px 4px;

    .mat-pseudo-checkbox {
      display: none;
    }

    &.eca-primary-button-color {
      background-color: var(--drc-blue);
      border-color: var(--drc-blue);
      color: var(--drc-white);
    }

    &:first-of-type {
      border-radius: 4px 0 0 4px;
    }
    & {
      border-right-width: 0;
    }
    &:last-of-type {
      border-radius: 0 4px 4px 0;
      border-right-width: 2px;
    }

    @include focused();

    .mat-icon {
      vertical-align: top;
    }

    button {
      z-index: 2;
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
    }
    .mat-button-toggle-focus-overlay {
      border: 2px solid transparent;
      background-color: transparent;
      bottom: 0;
      left: 6px;
      right: 0;
      top: 0;
      transition: inherit;
      width: calc(100% - 12px);
      z-index: 9999;
    }
    & + .mat-button-toggle.mat-button-toggle-appearance-standard {
      border-left: 2px solid var(--drc-light-grey);
      &.eca-primary-button-color {
        border-left-color: transparent;
      }

      &-checked {
        @include checked();
      }
    }

    &-checked {
      @include checked();
    }
  }
}
