@use 'theme';
@use 'focus-mixin';
@use 'colors';

// https://github.com/WICG/focus-visible is a polyfill for the :focus-visible pseudo-selector
// this lets us set keyboard focus styles while not impacting mouse focus styles

// This BASE style is imported (in slightly different ways) by both ./focus-visible.scss and eca-elements/src/styles.scss
// see button.scss and/or monotron.component.scss for related button styling

a {
  // prevents wrapping of link text which indirectly fixes the size & position of the hover/focus style
  display: inline-block;
}

a, button {
  &:focus:not(.focus-visible) {
    outline: none;
  }
}

a.eca-card {
  outline: none;
}

// these styling rules are all for different flavors of links (whether <a> or <button> styled as link)

a:not(.eca-card),
.eca-link:not([disabled]),
button.remove-btn-formatting:not([disabled]) {

  position: relative;

  // keyboard focus and mouse hover have the same outline & background

  &:not(.eca-focus-visible-inverse-colors):not(.eca-focus-visible-outline) {
    &.focus-visible, &:hover {
      outline: none;
      text-decoration: none;
      background-color: colors.$drc-white;

      &::before {
        @include focus-mixin.focus-outline;
      }
    }
  }
}


.eca-monotron-wrapper, .drc-dark-grey-bg {
  a:not(.eca-focus-visible-inverse-colors),
  .eca-link:not([disabled]),
  button.remove-btn-formatting:not([disabled]) {

    &.focus-visible, &:hover {
      color: theme.$drc-link-color;
    }
  }
}

// for the header toolbar and menu invert the color and background color, except for the logo
.drc-header-toolbar, .header-menu-panel {

  a,
  .eca-link:not([disabled]),
  button.remove-btn-formatting:not([disabled]) {
    &.focus-visible, &.cdk-focused.mat-mdc-icon-button, &:hover {
      &.eca-focus-visible-inverse-colors {
        outline: none;
        text-decoration: none;
        color: var(--drc-light-grey);
        border: none;

        &:not(#eca-help-menu-link,#eca-user-profile-menu-link,.eca-notification-history)::before {
          @include focus-mixin.focus-outline-inverse-colors;
        }

        .mat-icon {
          color: theme.$drc-link-color;
        }

        &#eca-user-profile-menu-link, &.eca-notification-history {
          background-color: var(--drc-light-blue);
        }
        .user-profile-link {
          background-color: theme.$drc-link-color;
          color: colors.$drc-light-blue;
          outline: none;
        }
      }
    }
  }

}
