@use './focus-mixin';
@use 'colors';

.mat-mdc-slide-toggle.mat-accent {
  margin-right: 16px;
  margin-top: 9px;
  margin-bottom: 9px;

  $handle-shadow: 0 2px 1px -1px #0003, 0 1px 1px #00000024, 0 1px 3px #0000001f;

  // Override CSS variables for customizing colors, size, shape, shadows
  --mdc-switch-handle-width: 20px; // +4px from border = 24px
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 50%;
  --mdc-switch-handle-surface-color: var(--drc-blue);
  --mdc-switch-state-layer-size: 0;

  // icon
  --mdc-switch-selected-icon-color: var(--drc-blue);
  --mdc-switch-unselected-icon-color: var(--drc-white);
  --mdc-switch-disabled-selected-icon-color: var(--drc-white);
  --mdc-switch-disabled-unselected-icon-color: var(--drc-white);
  --mdc-switch-selected-icon-size: 0;
  --mdc-switch-unselected-icon-size: 0;

  // colors for selected state
  // layer
  --mdc-switch-selected-focus-state-layer-color: var(--drc-blue);
  --mdc-switch-selected-hover-state-layer-color: var(--drc-blue);
  --mdc-switch-selected-pressed-state-layer-color: var(--drc-blue);
  // handle
  --mdc-switch-selected-focus-handle-color: var(--drc-blue);
  --mdc-switch-selected-hover-handle-color: var(--drc-blue);
  --mdc-switch-selected-pressed-handle-color: var(--drc-blue);
  --mdc-switch-selected-handle-color: var(--drc-blue);
  // track
  --mdc-switch-selected-focus-track-color: var(--drc-medium-blue);
  --mdc-switch-selected-hover-track-color: var(--drc-medium-blue);
  --mdc-switch-selected-pressed-track-color: var(--drc-medium-blue);
  --mdc-switch-selected-track-color: var(--drc-medium-blue);

  // colors for unselected/disabled state
  // disabled
  --mdc-switch-disabled-selected-handle-color: var(--drc-white);
  --mdc-switch-disabled-unselected-handle-color: var(--drc-white);
  --mdc-switch-disabled-selected-track-color: var(--drc-medium-grey);
  --mdc-switch-disabled-unselected-track-color: var(--drc-medium-grey);
  --mdc-switch-disabled-track-opacity: 1;
  // layer
  --mdc-switch-unselected-focus-state-layer-color: var(--drc-white);
  --mdc-switch-unselected-hover-state-layer-color: var(--drc-white);
  --mdc-switch-unselected-pressed-state-layer-color: var(--drc-white);
  // track
  --mdc-switch-unselected-focus-track-color: var(--drc-medium-blue);
  --mdc-switch-unselected-hover-track-color: var(--drc-medium-blue);
  --mdc-switch-unselected-pressed-track-color: var(--drc-medium-blue);
  --mdc-switch-unselected-track-color: var(--drc-medium-blue);
  // handle
  --mdc-switch-unselected-focus-handle-color: var(--drc-white);
  --mdc-switch-unselected-hover-handle-color: var(--drc-white);
  --mdc-switch-unselected-pressed-handle-color: var(--drc-white);
  --mdc-switch-unselected-handle-color: var(--drc-white);

  .mdc-switch {
    margin: 0 16px;
    border-radius: 7px;

    .mdc-switch__handle {
      --mdc-switch-selected-handle-color: var(--drc-blue);
    }

    &.mdc-switch--selected {
      .mdc-switch__handle {
        border: 2px solid var(--drc-white);
      }
    }
    &.mdc-switch--unselected {
      .mdc-switch__handle {
        border: 2px solid var(--drc-blue);
      }
    }
    .mdc-switch__handle {
      box-shadow: $handle-shadow;
      &:before {
        border: none;
      }
    }
    &[disabled] {
      .mdc-switch__handle {
        border: 2px solid var(--drc-medium-grey);

        &:before {
          background-color: var(--drc-white);
        }
      }
    }
  }

  label.mdc-label {
    color: var(--drc-black);
  }

  /* focus */
  &:not([disabled]) {
    &.cdk-focused, &:focus, &:hover {
      .mdc-switch::before {
        @include focus-mixin.focus-outline-toggle;
      }
    }
  }
}
