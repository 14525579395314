@use 'sass:map';
@use "sass:meta";

/*
  Below is a more efficient way to handle column widths and reduce boilerplate styles

  $columns is a map of maps.
   The first key is the type of table (eg. 'upload', 'import', 'item-pool-list', etc.)
   The second key is the column name (eg. 'fileName', 'status', 'clientCode', etc.).

  The value of the second key is the width of the column in pixels.

  The shared map is used for columns that are shared between tables in the same component.

  Example usage:
  ---------------component.scss---------
  @use '@drc-eca/eca-components-lib/src/styles/table-columns';

  $columns: (
    'shared': ( // <-- NOTE: shared is necessary, add a blank one if you don't use shared
      'name': 190,
      'clientCode': 90,
      'program': 145,
      'description': 350
    ),
    'workflow': (
      'availabilityStatus': 145,
      'ecosystem': 145,
      'portalApplication': 145
    ),
    'component': (
      'componentType': 145
    )
  );

  @include table-columns.define-widths($columns);

  ---------------component.html---------
  <div class="eca-sticky-columns-table-container">
    <mat-table
      matSort
      [dataSource]="dataSource"
      #stickyTable
      class="{{tableType}}-table" <!-------( NOTE: class name must end in "-table" )-->
      (matSortChange)="customSort($event)"
      tabindex="0" >

      <ng-container matColumnDef="name" sticky>
        <mat-header-cell *matHeaderCellDef mat-sort-header>
          {{searchType}} Name
        </mat-header-cell>
        <mat-cell *matCellDef="let item">
          <a style="cursor: pointer;" (click)="editWorkflow(item.id)">{{item.name}}</a>
        </mat-cell>
      </ng-container>

    </mat-table>
  </div>

*/

@function get-numerical-width($value) {
  $finalWidth: $value;
  @if meta.type-of($value) == 'map' {
    $finalWidth: map.get($value, 'flex-basis');
  }
  @return $finalWidth;
}

/*
  The following function is used to generate the widths for the table:
  add up all column widths
  + 16 * x where x = number of columns
  + 24 * 2 for outer left and right padding
  eg. 8 columns x 100px + 16 * 8 + 24 * 2 = 976px
*/
@function get-total-widths($keys) {
  $total: 0;
  $colPaddingWidth: 0;
  $colCount: 0;

  @each $key in $keys {
    @each $column, $width in $key {
      $numericalWidth: get-numerical-width($width);
      $total: $total + $numericalWidth;
      $colCount: $colCount + 1;
    }
  }
  $colPaddingWidth: 16 * $colCount;
  @return $total + $colPaddingWidth + 48;
}

@mixin set-row-widths($totalWidth) {
  .mat-mdc-header-row,
  .mat-mdc-footer-row,
  .mat-mdc-row {
    min-width: #{$totalWidth}px;
  }
}

/*
    This is set up to handle multiple tables in the same component.

    If you need different widths for the same column name do not use the shared
    map, and instead define the column in the table specific map.
*/

/*
  Call this mixin from your component with one or more tables
*/
@mixin define-widths($columns, $grow) {
  .eca-sticky-columns-table-container {
    overflow: auto;
    border: 1px solid var(--drc-black);
  }

  .mat-mdc-table {
    border: 0;
    * {
      box-sizing: content-box;
    }

    // Define the table width by type/instance
    @each $type, $columnList in $columns {
      @if $type != 'shared' {
        $totalWidth: get-total-widths((map_get($columns, 'shared'), $columnList));
        &.#{$type}-table {
          @include set-row-widths($totalWidth);
        }
      }
    }

    // Define column widths based on the columns map
    @each $type, $columnList in $columns {
      @each $column, $width in $columnList {
        $typetable: if($type != 'shared', '&.#{$type}-table', '');

        #{$typetable} .mat-column-#{$column} {
          @if meta.type-of($width) == 'number' {
            flex: $grow 0 #{$width}px;
          } @else {
            flex: #{map.get($width, 'flex-grow') 0 map.get($width, 'flex-basis')}px;
          }
        }
      }
    }

    .mat-mdc-row {
      border-bottom: 1px solid var(--drc-black);
    }
  }
}
