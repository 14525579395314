@use "sass:math";
@use "colors";
@use "theme";
@use "form-theme";

$date-panel-button-color: #585858;
$date-header-border: #dedede;

$cal-button-padding: 14px;

.mat-datepicker-input[type="date"] {
  // The date type adds 2 extra pixels in chrome and 4 extra pixels in safari
  height: 24px;

  // The date type adds a calendar icon to the native input element on Chrome
  &::-webkit-inner-spin-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
}

.mat-mdc-form-field-type-mat-date-range-input {
  .mat-date-range-input {
    margin-top: 2px;
    margin-bottom: -2px;
  }

  &.mat-mdc-form-field-has-icon-suffix div.mat-mdc-form-field-infix {
    width: 208px;
  }

  .mat-date-range-input-inner,
  .mat-date-range-input-mirror {
    line-height: 24px;
  }
}

/* Date Input CSS */
.mat-mdc-form-field-type-mat-input.mat-form-field-appearance-outline,
.mat-mdc-form-field-type-mat-date-range-input.mat-form-field-appearance-outline {

  .mat-mdc-form-field-icon-suffix .mat-datepicker-toggle {
    .mat-mdc-icon-button {
      position: absolute;
      display: block;
      top: -22px;
      right: 0;
      height: 44px;
      width: 44px;
      background-color: transparent;
      border-color: transparent;
      padding: 8px;
      color: var(--drc-black);
      line-height: 24px;

      --mat-icon-button-hover-state-layer-opacity: 0;
      --mat-icon-button-focus-state-layer-opacity: 0;
      --mat-icon-button-pressed-state-layer-opacity: 0;

      &:hover:not([disabled]),
      &.focus-visible:not([disabled]),
      &:focus:not([disabled])::before {
        padding: 7px;
        line-height: 38px;
      }
    }
  }

  &.eca-shrink-width {
    max-width: min-content;
  }
}

/* Date Panel CSS */
.mat-calendar-body-label {
  opacity: 0;
  pointer-events: none;

  // Fix for label taking an entire row (See October 2018)
  &[colspan="7"] {
    position: absolute;
  }
}

.mat-datepicker-content {
  padding: 22px;
  border: 2px solid var(--drc-black);
  // Sets the datepicker directly on top of the input when no space
  //   is available below the input without there is a 4 pixel gap
  margin-bottom: -4px;
  position: relative;

  .mat-calendar {
    position: relative;
    min-width: 305px;
    min-height: 360px;

    &-content {
      padding: 18px 0 0 0;
    }
    &-header {
      padding: 0;
    }

    &-body-cell {

      font-weight: 600;
      font-size: 16px;
      background-color: colors.$drc-white;
      // Material puts styles on the cell element
      //   Unfortunately override with !important
      $ratio: math.div(100, 14) * 1%;
      padding-top: calc($ratio - 1px) !important;
      padding-bottom: calc($ratio - 1px) !important;

      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        color: colors.$drc-blue;
      }
    }

    &-controls {
      margin: 0;
    }
  }
}

mat-month-view,
mat-year-view,
mat-multi-year-view {
  position: relative;
  display: block;
}

mat-year-view {
  .mat-calendar-body-label {
    opacity: 0;
    pointer-events: none;

    // Fix for label taking an entire row (See October 2018)
    &[colspan="4"] {
      position: absolute;
    }
  }
}

.mat-calendar-header {
  .mat-calendar-previous-button {
    &::after {
      transform: translateX(2px) rotate(-45deg);

      &:hover:not([disabled]),
      &.focus-visible:not([disabled]),
      &:focus:not([disabled]) {
        transform: translateX(2px) rotate(-45deg);
      }
    }
  }
  .mat-calendar-next-button {
    margin-left: 20px;

    &::after {
      transform: translateX(-2px) rotate(45deg);

      &:hover:not([disabled]),
      &.focus-visible:not([disabled]),
      &:focus:not([disabled]) {
        transform: translateX(-2px) rotate(45deg);
      }
    }
  }
  .mat-calendar-previous-button,
  .mat-calendar-next-button {

    height: 44px;
    width: 44px;

    &.mat-mdc-icon-button:not(:disabled) {
      border: 2px solid colors.$drc-blue;
    }

    &:after {
      border-color: colors.$drc-blue;
      margin: auto;
      width: 6px;
      height: 6px;
    }
  }
}

.mat-calendar-body {
  border: 2px solid colors.$drc-light-grey;
  background-color: colors.$drc-light-grey;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 46px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-color: colors.$drc-light-grey;
  }

  &-today:not(&-selected):not(&-comparison-identical) {
    border: 3px solid colors.$drc-blue;
  }

  &-cell:not(&-disabled):hover > &-cell-content:not(&-selected):not(&-comparison-identical),
  .cdk-keyboard-focused &-active > &-cell-content:not(&-selected):not(&-comparison-identical),
  .cdk-program-focused &-active > &-cell-content:not(&-selected):not(&-comparison-identical) {
    color: colors.$drc-blue;
    background-color: colors.$drc-light-blue;
  }

  &-cell:not(&-disabled):hover > &-cell-content:not(&-selected):not(&-comparison-identical),
  .cdk-keyboard-focused &-active > &-cell-content:not(&-selected):not(&-comparison-identical),
  .cdk-program-focused &-active > &-cell-content:not(&-selected):not(&-comparison-identical) {
    color: colors.$drc-blue;
    border: 3px solid colors.$drc-blue;
  }

  &-disabled > &-cell-content:not(&-selected):not(&-comparison-identical) {
    color: colors.$drc-dark-grey;
    background-color: colors.$drc-light-grey;
  }

  &-in-range {
    &::before {
      background: none;
    }

    .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: colors.$drc-medium-blue;
      border-width: 0px;
      border-top: 3px solid colors.$drc-blue;
      border-bottom: 3px solid colors.$drc-blue;
    }
  }

  .mat-calendar-body-selected {
    background-color: colors.$drc-blue;
  }

  &-in-preview {
    &:not(.mat-calendar-body-preview-end):not(.mat-calendar-body-preview-start) {
      .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
        background-color: colors.$drc-medium-blue;
        border-width: 0px;
        border-top: 3px solid colors.$drc-blue;
        border-bottom: 3px solid colors.$drc-blue;
      }
    }
    .mat-calendar-body-cell-preview {
      border: none;
    }
  }
}

.mat-calendar-table {
  .mat-calendar-table-header {
    background-color: colors.$drc-white;
    color: colors.$drc-black;
    position: relative;
    z-index: 2;

    th {
      font-size: 16px;
      padding: 0;
      line-height: 32px;
      font-weight: 600;
      position: relative;
    }

    &-divider {
      display: none;
    }
  }
}

eca-datepicker-calendar-header {
  position: relative;

  .mat-calendar-controls {
    .mat-mdc-button {
      &:first-child {
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        flex-grow: 1;
      }
      &:last-child {
        margin-left: 20px;
      }
    }
    .mat-calendar-spacer {
      display: none;
    }
  }
}

.mat-calendar-body-cell {
  border: 2px solid colors.$drc-light-grey;
  // Fixes Firefox paint order (background over border)
  background-clip: padding-box;

  .mat-calendar-body-cell-content {
    border-radius: 0;
    width: auto;
    height: auto;
    top: -2px;
    left: -2px;
    bottom: 0;
    right: 0;
  }
}

// Outer cdk panel
.cdk-overlay-container .mat-datepicker-content {
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
}

.mat-calendar-period-button {
  .mat-calendar-arrow {
    width: 8px;
    height: 8px;
    border-left: 0;
    border-bottom: 0;
    border-top: 2px solid colors.$drc-blue;
    border-right: 2px solid colors.$drc-blue;
    border-color: colors.$drc-blue;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin: 0;
    margin-bottom: 3px;
    margin-left: 8px;

    &.mat-calendar-invert {
      transform: rotate(315deg);
      top: 1px;
    }
  }
}
