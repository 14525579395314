@use 'colors';
@use 'form-theme';

/* Tab overrides with 2 designs: normal tabs and dialog tabs */

$mat-tab-height: 44px;
$mat-tab-line-height: 24px;
$mat-tab-x-padding: 8px;
$mat-tab-y-padding: 6px;
$mat-tab-x-label-padding: 12px;
$mat-tab-label-active-color: colors.$drc-blue;
$mat-tab-color: colors.$drc-blue;

$mat-tab-hover-color: colors.$drc-white;
$mat-tab-hover-bg-color: colors.$drc-accent-blue;
$mat-mdc-tab-disabled-color: colors.$drc-dark-grey;
$mat-mdc-tab-disabled-bg-color: colors.$drc-light-grey;

$mat-dialog-tab-height: 54px;

$mat-tab-header-border-color: colors.$drc-light-grey;

.mat-mdc-tab-group.mat-primary {

  --mdc-tab-indicator-active-indicator-color: transparent;
  --mat-tab-header-active-focus-indicator-color: transparent;
  --mat-tab-header-disabled-ripple-color: transparent;
  --mat-tab-header-inactive-ripple-color: transparent;
  --mat-tab-header-active-ripple-color: transparent;
  --mat-tab-header-active-label-text-color: var(--drc-blue);
  --mat-tab-header-inactive-label-text-color: var(--drc-blue);

  .mat-mdc-tab-header {
    --mdc-tab-indicator-active-indicator-height: 0;
  }

  // by default tabs should shrink to the width of their label
  .mat-mdc-tab-list {
    flex-grow: 0;
  }
  &[ng-reflect-stretch-tabs=true] {
    .mat-mdc-tab-list {
      flex-grow: 1;
    }
  }

  .mat-mdc-tab, .mat-mdc-tab-link {
    min-width: auto;
    padding: $mat-tab-y-padding $mat-tab-x-padding;
    height: $mat-tab-height;
    font-weight: 600;
    line-height: $mat-tab-line-height;
    color: $mat-tab-color;
    opacity: 1;
    flex-grow: 0;
  }
  .mdc-tab--active {
    opacity: 1;
    font-weight: 600;
    color: $mat-tab-label-active-color;
    border-radius: 4px 4px 0 0;
    box-shadow: 0 0 15px rgb(0 0 0 / 25%), inset 0 3px 0 colors.$drc-blue;
    z-index: 1;

    &:hover, &.cdk-focused, :focus {
      background-color: var(--drc-white);
      border-radius: 4px 4px 0 0;
      box-shadow: 0 0 15px rgb(0 0 0 / 25%), inset 0 3px 0 colors.$drc-accent-blue;

      .mdc-tab__text-label {
        color: $mat-tab-hover-color;
      }
      .mdc-tab__content {
        background-color: $mat-tab-hover-bg-color;
      }
    }
  }
  .mat-mdc-tab-label-container {
    overflow: hidden;
    background-color: colors.$drc-white;

    .mdc-tab__text-label {
      transition: none;
      transition-delay: 0ms;
    }

    .mdc-tab__content {
      padding: 0 $mat-tab-x-label-padding;
      border-radius: 4px;
      height: 32px;
    }

    .mat-mdc-tab:not(.mat-mdc-tab-disabled, .mdc-tab--active):focus {
      background-color: initial;
    }
    .mat-mdc-tab:not(.mdc-tab--active, .mat-mdc-tab-disabled) {
      border-bottom: 2px solid $mat-tab-header-border-color;
    }
    .mat-mdc-tab:not(.mat-mdc-tab-disabled) {
      &.cdk-keyboard-focused, &.cdk-program-focused {
        background-color: var(--drc-white);
        .mdc-tab__content {
          background-color: $mat-tab-hover-bg-color;
        }
      }
    }
    .mat-mdc-tab, .mat-mdc-tab-link {
      &:not(.mat-mdc-tab-disabled, .mdc-tab--active) {
        &:hover, &.cdk-focused {
          background-color: var(--drc-white);
          border-radius: 4px 4px 0 0;
          .mdc-tab__text-label {
            color: $mat-tab-hover-color;
          }
          .mdc-tab__content {
            background-color: $mat-tab-hover-bg-color;
          }
        }
      }
      &.mat-mdc-tab-disabled {
        color: $mat-mdc-tab-disabled-color;
        background-color: $mat-mdc-tab-disabled-bg-color;
        border-radius: 4px 4px 0 0;
      }
    }

    .eca-mat-tab-link-badge {
      display: inline-block;
      background: colors.$drc-red;
      border-radius: 12px;
      font-size: 16px;
      color: colors.$drc-white;
      letter-spacing: 0.16px;
      margin-left: 4px;
      text-align: center;
      height: 24px;
      line-height: 24px;
      min-width: 24px;
    }
  }
}

nav.mat-mdc-tab-nav-bar {

  .header-sub-nav.mat-mdc-tab-link:first-of-type {
    // 24 + 8 (padding) = 32;
    margin-left: 24px;
  }

  .header-sub-nav.mat-mdc-tab-link:last-of-type {
    // 24 + 8 (padding) = 32;
    margin-right: 24px;
  }
  &.mat-primary .mat-mdc-tab-link:not(.mat-mdc-tab-disabled):focus {
    background-color: $mat-tab-hover-bg-color;
  }
  .mdc-tab--active {
    opacity: 1;
    font-weight: 600;
    color: $mat-tab-label-active-color;
  }
}

.mat-mdc-dialog-container {

  .mat-mdc-tab-group.mat-primary {
    .mat-mdc-tab {
      height: $mat-dialog-tab-height;
    }
  }

  nav.mat-mdc-tab-nav-bar {
    .mat-mdc-tab-link {
      height: $mat-dialog-tab-height;
    }
  }

}
