@use 'theme';
@use 'colors';

mat-accordion.mat-accordion .mat-expansion-panel {
  margin-bottom: -1px;
  border-radius: 0;

  &:last-of-type {
    margin-bottom: initial;
  }

  &:first-of-type, &:last-of-type {
    border-radius: 0;
  }

  &:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  &.mat-expansion-panel-spacing {
    margin-top: 0;

    &:not(:last-of-type) {
      margin-bottom: -1px;
    }
  }

  &.mat-expanded {
    z-index: 2;

    .mat-expansion-panel-header {
      border-color: colors.$drc-dark-grey;

      &:hover,
      &:focus,
      &:focus-visible,
      &.focus-visible,
      &.cdk-focused {
        &:not([aria-disabled=true]) {
          .mat-expansion-panel-header-title {
            color: colors.$drc-blue;
          }
        }
      }
    }

    .mat-expansion-panel-header-title {
      color: colors.$drc-dark-grey;
    }

    .mat-expansion-panel-content {
      margin-top: -1px;
      margin-bottom: 0;
      z-index: 2;
    }
  }

  .mat-expansion-panel-body {
    padding: 16px 15px 15px 15px;
  }

  .mat-expansion-panel-content {
    position: relative;
    border: 1px solid colors.$drc-dark-grey;
    border-top: none;
    margin-bottom: -1px;
    z-index: 1;
    background-color: colors.$drc-white;
  }

  .mat-expansion-panel-header {
    border: 1px solid colors.$drc-medium-grey;
    background-color: colors.$drc-light-grey;
    height: 43px;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;

    &:hover,
    &:focus,
    &:focus-visible,
    &.focus-visible,
    &.cdk-focused {
      &:not([aria-disabled=true]) {
        z-index: 3;
        border-width: 3px;
        padding-left: 12px;
        padding-right: 12px;
        background-color: colors.$drc-light-blue;
        border-color: colors.$drc-blue;
      }
    }
  }

  .mat-expansion-panel-header-title {
    font-size: 16px;
    font-weight: 600;
    color: colors.$drc-blue;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .mat-expansion-indicator {
    font-size: 0;

    &::after {
      position: relative;
      top: -2px;
      color: colors.$drc-blue;
    }
  }

  .mat-expansion-panel-body {
    & > :first-child {
      margin-top: 0;
    }
  }
}

.mat-accordion.eca-accordion-lg .mat-expansion-panel {
  .mat-expansion-panel-header-title {
    font-size: 20px;
  }

  .mat-expansion-indicator::after {
    padding: 4px;
  }
}

.mat-accordion {
  &.eca-accordion-select {
    .mat-expansion-panel {
      margin-left: 44px;
    }

    & > .mat-mdc-checkbox:not(.mat-checkbox-disabled).cdk-focused .mat-checkbox-layout::before,
    & > .mat-checkbox:not(.mat-checkbox-disabled):focus
    & > .mat-checkbox-layout::before,
    & > .mat-checkbox:not(.mat-checkbox-disabled):hover .mat-checkbox-layout::before {
      box-sizing: border-box!important;
      width: initial;
      height: initial;
      top: 3px;
      right: 3px;
      bottom: 3px;
      left: 3px;
    }

    & > .mat-mdc-checkbox {
      position: absolute;
      .mdc-form-field {
        padding-left: 0;
        .mdc-checkbox {
          margin-right: 0;
        }
      }
    }
  }

  &:not(.eca-accordion-select) {
    & > .mat-mdc-checkbox {
      display: none;
    }
  }
}
