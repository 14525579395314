@use 'colors';

h1, h2, h3, h4, h5, h6, b, .subtitle-1, .subtitle-2 {
  color: colors.$drc-black;
  font-weight: 600;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  font-size: 28px;
  line-height: 42px;
}

h2 {
  font-size: 24px;
  line-height: 36px;
}

h3 {
  font-size: 22px;
  line-height: 33px;
}

h4 {
  font-size: 20px;
  line-height: 30px;
}

h5 {
  font-size: 18px;
  line-height: 27px;
}

h6 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.23px;
  word-spacing: 0.23px;
}

h1.sign-in-heading {
  font-size: 20px;
  line-height: 30px;
}


/**
 * existing styles below, not sure where these are used
 */

.subtitle-1 {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}

.subtitle-2 {
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
}

.body-1{
  font-size: 18px;
  line-height: 24px;
}

.body-2{
  font-size: 16px;
  line-height: 20px;
}

.tiny{
  font-size: 12px;
  line-height: 20px;
}
