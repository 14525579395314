@use 'theme';
@use 'colors';
@use 'focus-mixin';

$menu-bg: #343C48; //steel

.mat-mdc-menu-panel {
  // override the default of 280px;
  max-width: 80vw !important;
}

.mat-mdc-menu-panel.header-menu-panel {
  border-radius: 0 0 6px 6px;
  background: $menu-bg;
  box-shadow: none;
  max-width: inherit;

  position: relative;
  min-height: 32px;

  &.mega-menu {
    left: -26px;
  }

  /* This positions the menu correctly when its position is inverted (label at the bottom, menu opens upwards) */
  &[style*="transform-origin: left bottom"] {
    top: 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mat-mdc-menu-item {
    font-size: 16px;
  }

  .mat-mdc-menu-content {
    padding: 8px 2px;
  }
  .mat-mdc-menu-item ~ .mat-menu-header {
    margin-top: 10px;
  }
  .mat-menu-header {
    margin: 0 23px 7px;
    min-height: 32px;
    height: auto; // prevents overlapping menu header with the link below
    line-height: 32px;
    color: var(--drc-white);

    span {
      border-bottom: 1px solid var(--drc-light-grey);
      padding-bottom: 7px;
      padding-right: 10px;
      display: block;
    }
  }

  .mat-mdc-menu-item {
    color: var(--drc-white);
    padding: 0 23px;
    height: 32px;
    min-height: 32px;
    line-height: 32px;
    margin-left: 8px;
    margin-right: 8px;
    width: max-content;
    min-width: -webkit-fill-available;
    display: block;

    &:hover, &.focus-visible {
      &.eca-focus-visible-inverse-colors {
        box-shadow: 0 8px 10px -6px var(--drc-dark-grey-15),
        inset 0 -2px 0 var(--drc-amber),
        inset 0 2px 0 var(--drc-amber);
        border-radius: 4px;

        // override the focus-visible styles for other header links/buttons
        &::before {
          //box-shadow: none !important;
          border: none;
        }
      }
    }
  }
}

.mat-mdc-menu-panel.dropdown-menu-panel {
  border-radius: 4px;
  box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.15);
  border: 2px solid colors.$drc-blue;
  margin-bottom: -2px;
  margin-top: -2px;

  .mat-mdc-menu-content:not(:empty) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  span {
    width: 100%;
  }

  mat-slide-toggle.mat-mdc-slide-toggle {
    margin: 0;
    height: 36px;
    line-height: 36px;
    width: 100%;
  }
  .mdc-form-field {
    justify-content: space-between;
    width: 100%;

    .mdc-label {
      flex-grow: 1;
    }
    .mdc-switch__handle {
      box-sizing: content-box;
    }
  }

  // Remove the original mat slide toggle focus container
  .mat-mdc-slide-toggle:not(.mat-disabled).cdk-focused .mdc-switch::before,
  .mat-mdc-slide-toggle:not(.mat-disabled):focus .mdc-switch::before,
  .mat-mdc-slide-toggle:not(.mat-disabled):hover .mdc-switch::before {
    display: none;
  }

  .mat-mdc-menu-item {
    line-height: 44px;
    height: 44px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: 8px;
    margin-right: 8px;
    width: auto;
    min-width: -webkit-fill-available;

    mat-icon {
      overflow: visible;

      &::before {
        padding: 0;
        font-size: 24px;
        line-height: 1;
      }
    }

    &:hover:not([disabled]),
    &.cdk-program-focused:not([disabled]),
    &.cdk-keyboard-focused:not([disabled]),
    &-highlighted:not([disabled]) {
      background-color: colors.$drc-light-blue;
      color: theme.$drc-link-color;

      box-shadow: 0 8px 10px -6px
        var(--drc-dark-grey-15),
        inset 0px -2px 0px colors.$drc-accent-blue,
        inset 0px 2px 0px colors.$drc-accent-blue,
        inset -2px 0 0 colors.$drc-white,
        inset 2px 0 0 colors.$drc-white;
      border-radius: 4px;
      z-index: 1;
      .mat-mdc-menu-ripple {
        left: -2px;
        right: -2px;
        border-radius: 4px;
        z-index: 0;
      }
    }

    .mat-mdc-menu-trigger {
      padding-right: 32px;
    }
  }
}
