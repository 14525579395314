@use 'theme';

a {
  color: theme.$drc-link-color;
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;

  &.eca-card {
    text-decoration: none;
  }
}
