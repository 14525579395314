@use 'colors';
@use 'theme';
@use 'form-theme';

$btn-color: colors.$drc-blue;
$btn-border: colors.$drc-blue;
$btn-border-width: 2px;

$btn-disabled-bg: colors.$drc-light-grey;
$btn-disabled-color: colors.$drc-dark-grey;

$btn-primary-bg: colors.$drc-blue;
$btn-primary-border: colors.$drc-blue;
$btn-primary-color: colors.$drc-white;
$btn-primary-active-bg: #103a59;
$btn-primary-active-border: #122939;
$btn-primary-hover-bg: colors.$drc-light-blue;
$btn-primary-hover-border: colors.$drc-blue;
$btn-primary-hover-color: colors.$drc-blue;

$button-padding: 14px;
$button-height: 40px;

@mixin button-icon-size {
  $size: 24px;
  height: $size;
  width: $size;
  font-size: $size;
  line-height: $size;
  vertical-align: middle;
  padding-bottom: 2px;
}

// add exceptions here for mat-icon-buttons that should have a border
// TODO: consider refactoring this to use a mixin and reduce the length of generated selector rules
eca-autocomplete .eca-autocomplete,
eca-search-chip-list,
.eca-search,
eca-time .eca-time-input-container,
.mat-datepicker-toggle {
  button.mat-mdc-button, button.mat-mdc-icon-button, button.mat-mdc-icon-button, .mat-datepicker-close-button,
  & ~ button.mat-button, & ~ button.mat-icon-button, & ~ button.mat-mdc-icon-button {
    &.eca-icon-only, &.mat-mdc-icon-button, &.mat-mdc-icon-button {
      border-color: transparent;
      z-index: 1;

      --mat-icon-button-hover-state-layer-opacity: 0;
      --mat-icon-button-focus-state-layer-opacity: 0;
      --mat-icon-button-pressed-state-layer-opacity: 0;

      &:hover:not([disabled]),
      &.focus-visible:not([disabled]),
      &:focus-visible:not([disabled]),
      &.cdk-focused:not([disabled]),
      &:focus:not([disabled])::before {
        background-color: var(--drc-light-blue);
        border: form-theme.$hover-focus-border-width solid $btn-primary-hover-border;
        border-radius: 4px;
        box-shadow: none;
        padding: 0 7px !important;
        line-height: 38px !important; // 3px border x 2 = 44px total height/width
      }
    }
  }
}

.mat-datepicker-toggle {
  button.mat-mdc-button, button.mat-mdc-icon-button, .mat-datepicker-close-button,
  & ~ button.mat-button, & ~ button.mat-icon-button {
    &.eca-icon-only, &.mat-mdc-icon-button {
      &:hover:not([disabled]),
      &.focus-visible:not([disabled]),
      &:focus-visible:not([disabled]),
      &.cdk-focused:not([disabled]),
      &:focus:not([disabled])::before {
        padding: 7px;
        line-height: inherit;
      }
    }
  }
}

.mat-mdc-table, .eca-side-panel-close-button, .eca-no-border-icon-button {
  button.mat-mdc-button, button.mat-mdc-icon-button {
    &.eca-icon-only, &.mat-mdc-icon-button {
      border-color: transparent;
    }
  }
}
.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: var(--drc-blue);
}
button.mat-mdc-button, button.mat-mdc-icon-button, .mat-datepicker-close-button {

  border-radius: 4px;
  line-height: $button-height;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  border: $btn-border-width solid $btn-border;
  position: relative;
  min-width: initial;
  color: $btn-color;
  width: max-content;
  background-color: colors.$drc-white;

  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-icon-button-focus-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;

  --mat-mdc-button-persistent-ripple-color: var(--drc-white);
  --mat-text-button-state-layer-color: var(--drc-white);

  &.mat-primary {
    --mat-mdc-button-persistent-ripple-color: var(--drc-white);
    --mat-text-button-state-layer-color: var(--drc-white);
  }

  &.mat-mdc-button-base {
    --mdc-icon-button-state-layer-size: 44px;
  }
  .mat-mdc-button-touch-target {
    max-height: 44px; // default of 48px may cause overflow
    max-width: 44px; // default of 48px may cause overflow
  }

  &.mat-mdc-button-base {
    height: $button-height + calc($btn-border-width * 2);
  }

  &:not(.mat-mdc-icon-button):not(.eca-icon-only) {
    padding: 0 $button-padding;
    .mat-icon {
      margin-right: 8px;

      @include button-icon-size;

      &.left-spacing {
        margin-right: 0;
        margin-left: 4px;
      }
    }
  }

  &.mat-primary {
    background-color: $btn-primary-bg;
    border: $btn-border-width solid $btn-primary-border;
    color: $btn-primary-color;
  }

  &.eca-icon-only, &.mat-mdc-icon-button {
    padding: 0 8px;

    &:hover:not([disabled]),
    &.focus-visible:not([disabled]),
    &:focus-visible:not([disabled]),
    &.cdk-focused:not([disabled]),
    &:focus:not([disabled])::before {
      // compensate for shift from 2px to 0 border
      padding: $btn-border-width 10px;
    }

    .mat-icon {
      margin: 0;
      @include button-icon-size;
    }

    &.eca-suffix {
      border-radius: 0 4px 4px 0;
    }
  }
  .mat-icon {
    line-height: 20px;
  }

  /* Disabled State */
  &[disabled] {
    background-color: $btn-disabled-bg;
    border: $btn-border-width solid $btn-disabled-bg;
    color: $btn-disabled-color !important;
  }

  &.mat-datepicker-close-button {
    &:hover:not([disabled]),
    &.focus-visible:not([disabled]),
    &:focus-visible:not([disabled]),
    &.cdk-focused:not([disabled]),
    &:focus:not([disabled])::before {
      line-height: 36px;
    }
  }

  /* Hovered & Focused State for regular buttons */
  &:hover:not([disabled]),
  &.focus-visible:not([disabled]),
  &:focus-visible:not([disabled]),
  &.cdk-focused:not([disabled]),
  &:focus:not([disabled])::before {

    // compensate for border decrease from 2px to 0 so that the overall button size doesn't change
    padding: $btn-border-width $button-padding + 2;

    // hover state is the same for primary and secondary styles
    background-color: var(--drc-white);
    color: var(--drc-blue);
    border: none;
    box-shadow: 0 8px 10px -6px var(--drc-dark-grey-15),
      inset 0px -2px 0px var(--drc-accent-blue),
      inset 0px 2px 0px var(--drc-accent-blue);
  }

}

button.mat-mdc-button.eca-icon-only.add-border,
button.mat-button.mat-mdc-icon-button.add-border,
button.mat-icon-button.eca-icon-only.add-border,
button.mat-icon-button.mat-icon-button.add-border {
  &:not(:disabled) {
    border-color: $btn-primary-bg;
  }
}

.eca-monotron .eca-monotron-wrapper, .drc-dark-grey-bg, eca-header-base eca-site-selector {
  button.mat-mdc-button:not([disabled]) {
    &.mat-primary {
      border-color: colors.$drc-white;
    }

    &:hover,
    &.focus-visible,
    &:focus-visible,
    &.cdk-focused,
    &:focus::before {
      background-color: var(--drc-bright-accent-blue);
      color: var(--drc-black);
      border: none;
      box-shadow: 0 20px 25px -5px rgba(38, 45, 57, 0.5),
      inset 0px -2px 0px var(--drc-white),
      inset 0px 2px 0px var(--drc-white);
    }
  }
}

button.remove-btn-formatting {
  border: none !important;
  margin: 0;
  padding: 0;

  background: transparent;
  font-size: inherit;
  font-weight: 400;

  // skip to content should remain the button size, otherwise links are 24px
  &:not(#eca-skip-to-content-link,.nav-menu-link,.mdc-icon-button,#eca-user-profile-menu-link,.eca-notification-history) {
    height: 24px;
    line-height: 24px;
  }
}

.eca-link:not([disabled]) {
  color: theme.$drc-link-color;
  cursor: pointer;
  &:not(.mat-mdc-menu-item) {
    text-decoration: underline;
  }
}

// used by eca-search and eca-autocomplete : clear icon button
@mixin input-icon-button() {
  position: absolute;
  top: 0;
  right: -16px;
  background-color: transparent;
  height: inherit;
  width: inherit;
  max-width: 44px;

  &[disabled] {
    border: $btn-border-width solid transparent;
    background-color: transparent;

    .mat-icon {
      color: colors.$drc-light-grey;
    }
  }
}
