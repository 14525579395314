@use 'form-theme';
@use 'colors';
@use 'focus-mixin';

.mat-mdc-radio-button {

  // need position for the focus/hover outline to size correctly
  position: relative;
  display: inline-block;
  margin: form-theme.$hover-focus-border-width;

  &.mat-accent {

    --mdc-radio-selected-icon-color: var(--drc-black);
    --mdc-radio-selected-focus-icon-color: var(--drc-black);
    --mdc-radio-selected-hover-icon-color: var(--drc-black);
    --mdc-radio-selected-pressed-icon-color: var(--drc-black);

    --mdc-radio-unselected-icon-color: var(--drc-black);
  }

  .mdc-label {
    // effectively 16px between label and button
    padding-left: 6px;
    padding-right: 2px;
    position: relative;
    line-height: 30px;
  }

  .mdc-radio {
    --mdc-radio-state-layer-size: 30px;
  }
}

.mat-mdc-radio-group:not([disabled]) {

  /* Hover & focus */
  .mat-mdc-radio-button {

    &.cdk-focused, &:focus, &:hover, &.focus-visible {

      background-color: var(--drc-white);

      .mdc-form-field::before {
        @include focus-mixin.focus-outline-radio;
      }

    }

    .mdc-label {
      cursor: pointer;
    }
  }
}

// due to z-index issues with tables and the box-shadow hover/focus use the ripple background instead
.mat-mdc-header-cell, .mat-mdc-cell {

  .mat-mdc-radio-button {
    --mat-radio-ripple-color: var(--drc-accent-blue);
    --mat-radio-checked-ripple-color: var(--drc-accent-blue);

    &.cdk-focused, &:hover {
      --mdc-radio-selected-icon-color: var(--drc-white);
      --mdc-radio-selected-focus-icon-color: var(--drc-white);
      --mdc-radio-selected-hover-icon-color: var(--drc-white);
      --mdc-radio-selected-pressed-icon-color: var(--drc-white);

      --mdc-radio-unselected-icon-color: var(--drc-white);
      --mdc-radio-unselected-focus-icon-color: var(--drc-white);
      --mdc-radio-unselected-hover-icon-color: var(--drc-white);
      --mdc-radio-unselected-pressed-icon-color: var(--drc-white);
    }

    .mdc-radio {
      --mdc-radio-state-layer-size: 40px;

      .mdc-radio__native-control:not([disabled])~.mdc-radio__background::before {
        opacity: 1 !important;
        transition: none !important;
      }
    }

    .mdc-label {
      padding: 0
    }
  }
}
