@use 'colors';
@use 'include-media' as eca;

// override horizontal styles if should be vertical
.eca-vertical-stepper.mat-stepper-horizontal {

  .mat-horizontal-stepper-wrapper {
    display: flex;
    flex-direction: row;
  }

  .mat-horizontal-stepper-header-container {
    flex-direction: column;
    align-items: stretch;
    align-self: start;
    flex-shrink: 1;
  }

  .mat-horizontal-content-container {
    padding-top: 24px;
  }

  .mat-stepper-horizontal-line {
    border-width: 0;
  }

}

.mat-step-header {

  &.mat-vertical-stepper-header {
    width: auto;
  }

  &.mat-horizontal-stepper-header, &.mat-vertical-stepper-header {
    height: 44px;
    padding: 0 16px;
    margin: 8px;
    background-color: colors.$drc-white;

    &[aria-disabled='true'] {
      color: colors.$drc-dark-grey;

      .mat-step-icon {
        color: colors.$drc-dark-grey;
        background-color: colors.$drc-light-grey;
        border-color: colors.$drc-light-grey;
      }
    }

    &:not([aria-disabled='true']) {
      &:hover, &:focus-visible {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.25), inset 0px -2px 0px colors.$drc-accent-blue, inset 0px 2px 0px colors.$drc-accent-blue;
        border-radius: 4px;
        background-color: colors.$drc-white;
        border-bottom: none;

        &[tabindex='0'] {
          padding-bottom: 3px; // avoid the layout shift on the selected stepper
        }
      }
    }

    .mat-step-icon {
      background-color: colors.$drc-white;
      color: colors.$drc-dark-grey;
      border: 2px solid colors.$drc-dark-grey;
      font-weight: 600;

      &.mat-step-icon-selected {
        color: colors.$drc-white;
        background-color: colors.$drc-purple;
        border-color: colors.$drc-purple;
      }
      &.mat-step-icon-state-edit {
        color: colors.$drc-white;
        background-color: colors.$drc-blue;
        border-color: colors.$drc-blue;
      }
      &.mat-step-icon-state-done {
        color: colors.$drc-white;
        background-color: colors.$drc-green;
        border-color: colors.$drc-green;
      }
      @include eca.media('<sm') {
        margin-right: 0;
      }
    }

    .mat-step-label {
      @include eca.media('<sm') {
        display: none;
      }
    }
    .mat-step-text-label {
      color: colors.$drc-dark-grey;
      font-weight: 600;
    }
  }

  &[tabindex='0'] {
    height: 41px;
    border-bottom: 3px solid colors.$drc-blue;
  }

}

.mat-horizontal-stepper-header-container {
  .mat-stepper-horizontal-line {
    margin: 0;
    border-top-color: colors.$drc-medium-grey;
  }
}
