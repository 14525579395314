@use 'colors';
@use 'focus-mixin';
@use 'form-theme';

$checkbox-width: 18px;
$checkbox-height: 18px;
$checkbox-border: 2px solid colors.$drc-blue;

mat-label.checkbox {
  display: block;
  margin-bottom: 4px;
  font-size: 16px;
  font-weight: 400;
  line-height: 34px;
}

mat-checkbox + mat-checkbox:last-of-type {
  margin-bottom: 32px;
}

.mat-mdc-select-panel {
  .mat-pseudo-checkbox {
    width: $checkbox-width;
    height: $checkbox-height;

    border: $checkbox-border;

    cursor: pointer;
  }

  .mat-pseudo-checkbox-checked {
    background-color: colors.$drc-blue;

    &:after {
      top: 4px;
      left: 3px;
      height: 3px;
      width: 7px;

      border-left-width: 1px;
      border-bottom-width: 1px;

      // checkmark override for IE only
      @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 0 !important;
      }
    }
  }

  .mat-mdc-option-pseudo-checkbox {
    margin-right: 16px;
  }
}

.mat-mdc-checkbox {

  // make room for the focus/hover outline
  margin-left: form-theme.$hover-focus-border-width;
  white-space: normal;
  max-width: calc(80ch - 35px);

  // theme overrides
  &.mat-accent, &.mat-primary {
    --mdc-checkbox-state-layer-size: 34px;

    // Variables for Selected checkboxes (focus, hover, pressed)
    --mdc-checkbox-selected-checkmark-color: var(--drc-white);
    --mdc-checkbox-selected-icon-color: var(--drc-blue);

    --mdc-checkbox-selected-focus-icon-color: var(--drc-blue);
    --mdc-checkbox-selected-focus-state-layer-opacity: 1;
    --mdc-checkbox-selected-focus-state-layer-color: var(--drc-accent-blue);

    --mdc-checkbox-selected-hover-icon-color: var(--drc-blue);
    --mdc-checkbox-selected-hover-state-layer-opacity: 0;

    --mdc-checkbox-selected-pressed-icon-color: var(--drc-blue);
    --mdc-checkbox-selected-pressed-state-layer-opacity: 1;
    --mdc-checkbox-selected-pressed-state-layer-color: var(--drc-accent-blue);


    // Variables for Unselected checkboxes
    --mdc-checkbox-unselected-icon-color: var(--drc-blue);

    --mdc-checkbox-unselected-focus-state-layer-color: var(--drc-accent-blue);
    --mdc-checkbox-unselected-hover-state-layer-color: transparent;
    --mdc-checkbox-unselected-pressed-state-layer-color: var(--drc-accent-blue);

    --mdc-checkbox-unselected-focus-state-layer-opacity: 1;
    --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
    --mdc-checkbox-unselected-pressed-state-layer-opacity: 1;
  }

  /* Base container */
  .mdc-checkbox {
    width: $checkbox-width;
    height: $checkbox-height;
  }

  .mdc-form-field {
    padding-left: 8px;

    label {
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5;
      padding: 5px 16px 5px 8px;
      color: var(--drc-black);

      &:has(.cdk-visually-hidden) {
        // might have a hidden label for a11y reasons, like in a table
        padding: 0;
      }
    }
  }

  /* Hover & focus */
  &:not(.mat-mdc-checkbox-disabled) {
    &.cdk-focused, &:focus, &:hover, &.focus-visible {
      background-color: colors.$drc-white;

      &::before {
        @include focus-mixin.focus-outline-checkbox;
      }
    }

    .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate)~.mdc-checkbox__background {
      background-color: var(--drc-white) !important;
    }

    .mdc-form-field {
      label {
        z-index: 10;
      }
    }
  }

  /* we need to use this element for focus if we want a CSS only solution */
  .mdc-checkbox__ripple, .mat-mdc-checkbox-ripple {
    //display: none;
  }
}
.mat-nested-tree-node div[role=tree] {
  padding-left: 40px;
}

.mat-mdc-header-cell, .mat-mdc-cell {

  // styles to achieve 44x44px pad (& outline) around checkboxes in tables
  .mdc-form-field {
    padding: 4px;
  }
}
