
.filter-bar {
  display: -ms-flexbox;
  display: flex;
  flex-flow: row wrap;
  align-items: center;

  /* Groups label + field to ensure flex wrapping does not split them up */
  .filter-group,
  .button-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    padding: 0 18px 18px 0;
  }

  label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 16px;
    font-weight: 600;
    color: #585858;
    &:after {
      content: ':';
      display: block;
    }
  }

  .mat-mdc-radio-button .mdc-label:after {
    content: '';
  }

  /* Override mat-select extras to remove labels / subscripts */
  .mat-mdc-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline,
  .mat-mdc-form-field.mat-mdc-form-field-type-eca-multi-select.mat-form-field-appearance-outline,
  .mat-mdc-form-field.mat-mdc-form-field-type-eca-search.mat-form-field-appearance-outline,
  .mat-mdc-form-field.mat-mdc-form-field-type-eca-autocomplete.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      min-width: initial;
      width: fit-content;
    }
    .mat-mdc-text-field-wrapper {
      margin: 0;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }

    .mat-mdc-select-value {
      width: initial;
      min-width: 0;
      max-width: 120px;
      padding-right: 8px;
    }
  }

  /* Override mat-input */
  .mat-mdc-form-field.mat-mdc-form-field-type-mat-input.mat-form-field-appearance-outline {
    .mat-mdc-text-field-wrapper {
      margin: 0;
    }
    .mat-mdc-form-field-infix {
      width: initial;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  /* Override Radio Group */
  .mat-mdc-radio-group {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mat-mdc-checkbox {
    margin: 4px 10px 0 10px;

    label {
      margin: 0;

      &:after {
        display: none;
      }
    }
  }

  eca-search-suffix {
    margin-top: 0;
  }

  /* Button groups will stay justified right even if the rows wrap */
  .button-group {
    justify-content: flex-end;
    flex: 1 1 auto;
    box-sizing: border-box;
    min-width: auto;
    padding-right: 0;

    button + button {
      margin-left: 12px;
    }
  }

  /* Toggle group */
  mat-button-toggle-group.mat-button-toggle-group {
    border: 1px solid #acacac;
    box-shadow: none;
    &.mat-button-toggle-group-appearance-standard{
      border-radius: 2px;
    }
    .mat-button-toggle-label-content {
      line-height: 34px;
    }
    mat-button-toggle + mat-button-toggle {
      border-left: 1px solid #acacac;
    }
  }
}
