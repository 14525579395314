/* Include first to preserve CSS order precedence */
@forward 'src/styles/material-component-typography';
@forward 'src/styles/_theme.scss';
@forward 'src/styles/_colors.scss';
@forward 'src/styles/autocomplete';
@forward 'src/styles/bottom-sheet';
@forward 'src/styles/breadcrumb';
@forward 'src/styles/buttons';
@forward 'src/styles/button-toggle';
@forward 'src/styles/card';
@forward 'src/styles/checkbox';
@forward 'src/styles/chips';
@forward 'src/styles/dialog';
@forward 'src/styles/datepicker';
@forward 'src/styles/flexbox';
@forward 'src/styles/form-field';
@forward 'src/styles/footer';
@forward 'src/styles/filter-bar';
@forward 'src/styles/focus-visible';
@forward 'src/styles/header';
@forward 'src/styles/links';
@forward 'src/styles/list';
@forward 'src/styles/menu';
@forward 'src/styles/radio';
@forward 'src/styles/select';
@forward 'src/styles/shadows';
@forward 'src/styles/snackbar';
@forward 'src/styles/spinner';
@forward 'src/styles/stepper';
@forward 'src/styles/table';
@forward 'src/styles/table-columns';
@forward 'src/styles/table-filters';
@forward 'src/styles/tabs';
@forward 'src/styles/timepicker';
@forward 'src/styles/toggle';
@forward 'src/styles/tooltip';
@forward 'src/styles/tree';
@forward 'src/styles/expansion-panel';
@forward 'src/styles/heading-typography';
@forward 'src/styles/paragraph-typography';
@forward 'src/styles/validation';

html, body, button {
  font-family: "Source Sans Pro", Helvetica, Verdana, sans-serif !important;
  letter-spacing: 0.16px;
  word-spacing: 0.16px;

  // focus indicator
  --mat-focus-indicator-border-width: 0;
  --mat-mdc-focus-indicator-display: none;

  // select options
  --mat-option-hover-state-layer-color: var(--drc-white);
  --mat-option-focus-state-layer-color: none;
  --mat-option-selected-state-layer-color: none;
  --mat-option-label-text-weight: 400;

  // form fields
  --mdc-outlined-text-field-focus-label-text-color: var(--drc-black);
  --mdc-outlined-text-field-disabled-label-text-color: var(--drc-black);
  --mdc-outlined-text-field-error-label-text-color: var(--drc-black);
  --mdc-outlined-text-field-error-focus-label-text-color: var(--drc-black);
  --mdc-outlined-text-field-focus-outline-color: var(--drc-black);
  .mdc-text-field--outlined {
    // focus outline width is set to 3px in form-field, otherwise the label shifts
    --mdc-outlined-text-field-focus-outline-width: 2px;
  }
}


eca-pillar-box, eca-monotron, eca-monotron-body {
  .mat-mdc-tab-header {
    margin-top: -8px;
    justify-content: center;
    .mat-mdc-tab-label-container {
      flex-grow: 0;
      margin-bottom: 16px;
      .mat-mdc-tab-labels {
        min-height: 100%;
      }
    }
  }
  .content-column {
    max-width: 650px;
    margin: auto;
  }
}
