@use 'colors';

// text and background colors
$form-input-color: colors.$drc-black;
$form-input-background: colors.$drc-white;
$form-input-read-only-background: colors.$drc-white;
$form-input-disabled-background: colors.$drc-light-grey;
$form-input-selection: colors.$drc-yellow;

// border colors
$form-input-border: colors.$drc-black;
$form-input-error-border: colors.$drc-red;
$form-input-focus-border: colors.$drc-blue;
$form-input-disabled-border: colors.$drc-light-grey;

// label colors
$form-input-label-color: colors.$drc-black;
$form-input-label-invalid-color: colors.$drc-red;

// common size/layout values that may be dependent on each other
$font-size: 16px;
$line-height: 32px;

$focus-border-width: 3px;

@mixin focus-hover-border() {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__trailing {
    border-color: $form-input-focus-border;
  }
  .mdc-notched-outline__leading {
    border-width: $focus-border-width 0 $focus-border-width $focus-border-width !important;
  }
  .mdc-notched-outline__notch {
    border-color: transparent;
  }
  .mdc-notched-outline__trailing {
    border-width: $focus-border-width $focus-border-width $focus-border-width 0 !important;
  }
}

// temporary colors to maintain the existing styles for the eca-login-component
$lightPink: #F3DBD9;
$darkPink: #E8AEA6;
$lightGrey: #F1F1F1;

/* Mixin for the Caps Lock callout notch with border */
@mixin notch {
  content: ' ';
  position: absolute;
  left: -6px;
  top: 10px;
  margin: 0;
  padding: 0;
  border-left: none;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 5px solid $darkPink;
}

/*
 * Many of these styles target inner elements of the Material Input field
 * which is not officially supported. However, they are necessary to achieve
 * the styling that has been defined. The general hierarchy of elements follows:
 *
 * .mat-form-field
 *     .mat-mdc-text-field-wrapper
 *         .mat-mdc-form-field-flex
 *             .mdc-notched-outline
 *                 .mdc-notched-outline-start
 *                 .mdc-notched-outline-gap
 *                 .mdc-notched-outline-end
 *             .mat-mdc-form-field-infix
 *                 input.mat-input-element
 *                 .mat-mdc-floating-label-wrapper
 *                     label.mat-mdc-floating-label
 *                         mat-label
 *
 *     .mat-mdc-form-field-subscript-wrapper
 *         .mat-form-field-hint-wrapper
 *             mat-hint
 *         mat-error
 *
 * Here is the new v15 structure
 *
 *  .mat-mdc-form-field
 *    .mat-mdc-text-field-wrapper
 *      .mat-mdc-form-field-flex
 *        .mdc-notched-outline
 *          .mdc-notched-outline__leading
 *          .mdc-notched-outline__notch
 *            .mat-mdc-floating-label
 *              mat-label
 *          .mdc-notched-outline__trailing
 *        .mat-mdc-form-field-infix
 *          input.mat-mdc-input-element
 *    .mat-mdc-form-field-subscript-wrapper
 *      .mat-mdc-form-field-error-wrapper
 *      .mat-mdc-form-field-hint-wrapper
 */
.mat-mdc-form-field.mat-form-field-appearance-outline {

  --mdc-theme-error: var(--drc-red);
  --mat-mdc-form-field-floating-label-scale: 1;
  --mdc-typography-body1-font-weight: 400;
  --mdc-outlined-text-field-error-hover-outline-color: var(--drc-red);
  --mdc-outlined-text-field-error-focus-outline-color: var(--drc-red);
  --mdc-outlined-text-field-error-outline-color: var(--drc-red);

  --mdc-outlined-text-field-hover-label-text-color: var(--drc-black);
  --mdc-outlined-text-field-error-hover-label-text-color: var(--drc-black);

  .mat-mdc-text-field-wrapper {
    margin: 30px 0 8px 0;
    background-color: $form-input-background;
    flex: 0 1 auto; // prevent input height from growing if an adjacent field has a multi-line validation message

    &.mdc-text-field--outlined {
      --mdc-outlined-text-field-outline-width: 2px;
    }

    .mat-mdc-form-field-input-control:not(textarea) {
      height: $line-height;
    }
  }
  // for layouts where no validation or hints are needed
  &.eca-no-padding-bottom {
    .mat-mdc-text-field-wrapper {
      padding-bottom: 0;
    }
    .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  .mat-mdc-form-field-flex {
    &:hover {
      @include focus-hover-border;
    }

    .mdc-notched-outline {
      z-index: 0;
    }

    label mat-label {
      font-weight: 600;
    }
  }

  .mat-mdc-floating-label {
    top: 0;
    font-size: $font-size;
    color: $form-input-label-color;
    --mdc-outlined-text-field-label-text-color: $form-input-label-color;
    line-height: $line-height;
    font-family: 'Source Sans Pro', Helvetica, Arial, serif;
    overflow: visible;
    transform: translate(-4px, -60px) !important;
    transition: none !important;
    position: absolute;

    .mat-mdc-form-field-required-marker {
      color: $form-input-label-invalid-color;
    }
  }

  &.mat-mdc-form-field-type-eca-autocomplete {
    .mat-mdc-form-field-infix {
      padding-right: 28px;  // 28 + 16 = 44 which accounts for the clear icon button
    }
  }

  .mat-mdc-form-field-infix {
    padding: 5px 0;
    border-top: 0;
    min-height: 44px;
  }

  .mat-mdc-input-element {
    position: relative;
    top: 2px;
    color: $form-input-color;

    &::selection {
      background: $form-input-selection;
    }
  }

  /* Border Overrides */
  .mdc-notched-outline {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border-width: 2px;
      border-color: $form-input-border;
      border-top-style: solid; // take out the notch
    }
    .mdc-notched-outline__leading {
      border-radius: 4px 0 0 4px;
    }
    .mdc-notched-outline__notch {
      position: absolute;
      overflow: visible;
      left: 2px;
      width: 1px !important;
      max-width: 1px !important;
    }
    .mdc-notched-outline__trailing {
      border-radius: 0 4px 4px 0;
    }
  }
  &.eca-has-prefix {
    margin-left: -2px;
    .mdc-notched-outline {
      .mdc-notched-outline__leading {
        border-radius: 0 !important;
      }
    }
  }

  // assumes eca-search field has the search icon suffix
  &.eca-search {
    .mdc-notched-outline {
      .mdc-notched-outline__trailing {
        border-radius: 0;
      }
    }
  }

  /* Focused Border Overrides */
  &.mat-focused {
    .mat-mdc-form-field-flex {
      @include focus-hover-border;
    }
  }

  /* Read-Only Overrides */
  &.eca-read-only {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-input-read-only-background !important;
        background-color: $form-input-read-only-background;
      }
    }
  }

  /* Disabled Overrides */
  &.mat-form-field-disabled {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: $form-input-disabled-border;
        background-color: $form-input-disabled-background;
      }
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    padding: 0;
    margin-top: -4px;
    display: inline-block;
    min-height: 32px;
    transform: none !important;

    .mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
      position: relative;
      display: inline-block;
      padding: 0 4px;
      transform: none !important;
    }
  }

  .mat-mdc-form-field-error, .mat-mdc-form-field-hint {
    font-size: $font-size;
    line-height: $line-height;
    margin-top: 0;
  }

  textarea {
    resize: none;
    padding-right: 12px; // push the scroll bar to the right, closer to the border
  }

  // Login and Password fields
  &.eca-login-component-field {

    width: 390px;

    input {
      box-sizing: border-box;
    }
  }

  &.eca-password-composite-field {

    &#loginPasswordFormField {
      margin-top: -3px;
    }

    // for the 'Show Text' checkbox and label
    .mat-mdc-form-field-icon-suffix {
      left: -6px;
      padding-left: 0;
      min-width: 124px;
      background-color: $lightGrey;

      .mat-mdc-checkbox {
        margin-left: 0;
      }
    }

    .caps-lock-tooltip {
      position: absolute;
      top: 6px;
      left: 380px;
      padding: 6px 8px;
      border: 1px solid $darkPink;
      border-radius: 4px;
      background-color: $lightPink;
      white-space: nowrap;
      font-weight: 600;

      /* Style overrides for IE only */
      @media screen and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        top: 10px;
      }

      /* This will display a "notch" to the left of the callout, pointing towards the input. */
      &::before {
        @include notch;
        border-right: 5px solid $darkPink;
        z-index: 1; // above the "border" notch
      }

      /* This will display below the notch above and shifted left a bit, giving the illusion that there is a border around the notch. */
      &::after {
        @include notch;
      }
    }
  }
}

.eca-required-marker {
  color: colors.$drc-red;
  margin-left: 0.2em;
}
.eca-label {
  font-weight: 600;
}

/* Invalid Border Overrides but only for fields that are not part of a login form */
:not(.eca-login-component-field).mat-form-field-appearance-outline {
    &.mat-form-field-invalid {
      .mdc-notched-outline {
        .mdc-notched-outline__leading,
        .mdc-notched-outline__notch,
        .mdc-notched-outline__trailing {
          border-color: $form-input-error-border;
        }
      }
    }
}

/* In Firefox, the typeface descender is hidden inside input fields, the line height is insufficient to display correctly letters such as g and q */
.mat-mdc-input-element {
  line-height: $line-height !important;
}
// for fields that need a label but aren't supported within a mat-form-field element
mat-label.eca-form-field-label, label.eca-form-field-label {
  display: block;
  margin-top: 6px;
  font-size: $font-size;
  line-height: $line-height;
  font-weight: 600;
  color: $form-input-label-color;
}

.eca-form-field-instructions {
  margin: 8px 0;
  + .mat-mdc-form-field {
    // remove the top margin since the label should be above the instructions
    margin-top: -26px;
  }
}
