.mat-mdc-progress-spinner circle,
.mat-mdc-progress-spinner circle {
  stroke: var(--drc-blue);
}

.mat-mdc-progress-bar.mdc-linear-progress {
  --linear-progress-height: 20px;
  --mdc-linear-progress-track-height: var(--linear-progress-height);
  --mdc-linear-progress-track-color: var(--drc-white);
  --mdc-linear-progress-active-indicator-height: var(--linear-progress-height);
  --mdc-linear-progress-active-indicator-color: var(--drc-blue);

  --mdc-linear-progress-track-shape: 4px;
  border-radius: 4px;
  outline: none;
  border: 2px solid var(--drc-black);

  .mdc-linear-progress__bar {
    margin: 0;
  }
}

.mat-mdc-progress-spinner.mdc-circular-progress {
  --mdc-circular-progress-active-indicator-color: var(--drc-blue);
  circle {
    stroke: var(--drc-blue) !important;
  }
}
