@use 'colors';

// mat-hint and mat-error intentionally defined outside of mat-form-field usage as not all fields use mat-form-field
// and not all validation messages will be directly next to form fields.

.mat-mdc-form-field-hint {
  color: var(--drc-black);
  --validation-icon-size: 20px;

  &.eca-success {
    color: var(--drc-green);

    &::before {
      --mat-form-field-subscript-text-font: "Material Icons Round";
      --mat-form-field-subscript-text-size: var(--validation-icon-size);
      font-family: "Material Icons Round";
      font-size: var(--validation-icon-size);
      height: var(--validation-icon-size);
      // 'Check' https://fonts.google.com/icons?selected=Material%20Icons%20Round%3Adone%3A
      content: "\e5ca";
      color: var(--drc-green);
      vertical-align: sub;
      padding-right: 8px;
    }
  }
}

.mat-mdc-form-field-error {
  color: var(--drc-red);
  --mat-form-field-error-text-color: var(--drc-red);
  --validation-icon-size: 20px;

  &::before {
    --mat-form-field-subscript-text-font: "Material Icons Round";
    --mat-form-field-subscript-text-size: var(--validation-icon-size);
    font-family: "Material Icons Round";
    font-size: var(--validation-icon-size);
    height: var(--validation-icon-size);
    display: inline-block;
    // 'Error' (exclamation in circle) https://fonts.google.com/icons?selected=Material%20Icons%20Round%3Aerror%3A
    content: "\e000" !important;
    color: var(--drc-red);
    vertical-align: sub;
    padding-right: 8px;
  }
}
