@use 'colors';

p.x-large{
  font-size: 20px;
  line-height: 32px;
}

p.large{
  font-size: 18px;
  line-height: 28px;
}

p {
  margin-top: 0;
  font-weight: 400;
  max-width: 80ch;
  font-size: 16px;
  line-height: 24px;
  color: colors.$drc-black;
}

p + p {
  margin-top: 32px;
}

p.small{
  font-size: 14px;
  line-height: 20px;
}

p.x-small{
  font-size: 12px;
  line-height: 16px;
}
