// see also https://material.io/design/layout/responsive-layout-grid.html#breakpoints

/**
  Current screen size constraints:
  1024 x 768 is the standard supported resolution
  256  x 192 (400% zoom of 1024 x 768) supported for accessibility reasons

  To reference a breakpoint use:
  map.get($breakpoints, 'md');

  using pixels instead of rem because all of our styles/designs are pixel-based and
  browser zoom takes care of the text size accessibility concerns

  These breakpoints are also defined in src/lib/shared/breakpoints.model.ts
  Angular flex-layout also gets its custom breakpoints from the breakpoint.model.ts file
 */

$breakpoint-widths: (
  xs: 256px,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1400px,
  xxl: 1600px
);

// heights in ascending order (not correlated to widths)
$breakpoint-heights: (
  xs: 192px,
  sm: 360px,
  md: 576px,
  lg: 768px,
  xl: 900px,
  xxl: 1050px
);
