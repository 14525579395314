
// Colors defined as of Feb 2021 - UXT-367

$drc-blue: var(--drc-blue);
$drc-insight-gold: var(--drc-insight-gold);
$drc-black: var(--drc-black);
$drc-amber: var(--drc-amber);

$drc-accent-blue: var(--drc-accent-blue);
$drc-bright-accent-blue: var(--drc-bright-accent-blue);
$drc-medium-blue: var(--drc-medium-blue);
$drc-light-blue: var(--drc-light-blue);
$drc-cool-grey: var(--drc-cool-grey);
$drc-dark-grey: var(--drc-dark-grey);
$drc-medium-grey: var(--drc-medium-grey);
$drc-light-grey: var(--drc-light-grey);
$drc-white: var(--drc-white);

$drc-green: var(--drc-green);
$drc-yellow: var(--drc-yellow);
$drc-red: var(--drc-red);
$drc-purple: var(--drc-purple);

// semi-transparent / shadow colors
// rgba(38, 45, 57, 0.15)
$drc-dark-grey-15: var(--drc-dark-grey-15);

:root {
  --drc-blue: #002A5F;
  --drc-insight-gold: #C88A12;
  --drc-black: #000000;
  --drc-amber: #DEB408;

  --drc-accent-blue: #3071AD;
  --drc-bright-accent-blue: #43A7DE;
  --drc-medium-blue: #82C3FF;
  --drc-light-blue: #EDF6FF;
  --drc-cool-grey: #545761;
  --drc-dark-grey: #262D39;
  --drc-dark-grey-15: #262D3926; // 15% translates to 26 in hexidecimal
  --drc-medium-grey: #B1B8C4;
  --drc-light-grey: #EFEFF0;
  --drc-white: #FFFFFF;

  --drc-green: #34520D;
  --drc-yellow: #FFFF00;
  --drc-red: #B50000;
  --drc-purple: #6E1492;
}
