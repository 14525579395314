@use 'colors';
@use 'form-theme';

@mixin chip-theme($color, $border, $background, $border-hover, $background-hover) {
  color: $color;
  border-color: $border;
  background-color: $background;

  &:hover, &:focus, &.cdk-focused,
  &.mat-chip-selected.mat-primary.mat-mdc-standard-chip {
    color: $color;
    border-color: $border-hover;
    background-color: $background-hover;

    &::after {
      opacity: 0;
    }
    .mat-icon {
      color: $color;
      opacity: 1;
    }
  }
}

@mixin common-styles() {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 2px solid;
  border-radius: 4px;
  background-clip: padding-box;
}

.eca-chip-list {
  margin: 4px;
  .eca-chip {
    margin-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

// 'Input Chips'
.mat-mdc-form-field {
  &.mat-mdc-form-field-type-mat-chip-grid.mat-form-field-appearance-outline,
  &.mat-mdc-form-field-type-eca-search-chip.mat-form-field-appearance-outline {

    .mat-mdc-form-field-infix {
      display: flex;
      flex-flow: wrap;
      // force the field to grow when wide chips are added
      width: min-content;
      // avoid overlap of the clear all button
      padding-right: 16px;
    }

    .mat-mdc-chip-grid + .mat-mdc-input-element {
      top: 2px;
    }
  }
  .mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-label-text-weight: 600;
    --mdc-chip-container-height: auto;
    --mdc-chip-focus-state-layer-color: none;

    // margin to position the chips vertically and spaced appropriately
    margin: 0 4px 0 0;
    // since height is auto (to allow for multi-line chips, set padding to hit correct height
    padding: 4px 0;
    @include common-styles;
    @include chip-theme(colors.$drc-black, colors.$drc-white, colors.$drc-medium-blue, colors.$drc-blue, colors.$drc-light-blue);
    & {
      // for simplicity use the same border-width for default & hover/focus
      border-width: form-theme.$hover-focus-border-width;
      // keep chip text on one line as height is always 32px
      white-space: nowrap;
    }

    .mat-icon {
      font-size: 20px;
      height: 20px;
      width: 20px;
      color: colors.$drc-black;
      opacity: 1;
    }

    // chip text needs to wrap if it would not fit within the field
    .mdc-evolution-chip__text-label {
      white-space: normal;
      word-break: break-word;
    }
  }
}

// 'Communication Chips'
.mat-mdc-chip.mat-mdc-basic-chip, .eca-chip {
  @include common-styles;
  padding: 2px 8px;
  white-space: nowrap;

  &:focus {
    outline: none;
  }

  /*                   | Color            | Border            | Background      | Border Hover      | Background Hover */
  &.chip-success, &.chip-green {
    @include chip-theme(colors.$drc-white, colors.$drc-white, colors.$drc-green, colors.$drc-white, colors.$drc-green);
  }

  &.chip-danger, &.chip-red {
    @include chip-theme(colors.$drc-white, colors.$drc-white, colors.$drc-red, colors.$drc-white, colors.$drc-red);
  }

  &.chip-yellow {
    @include chip-theme(colors.$drc-black, colors.$drc-black, colors.$drc-yellow, colors.$drc-black, colors.$drc-yellow);
  }

  &.chip-default, &.chip-medium-grey {
    @include chip-theme(colors.$drc-black, colors.$drc-white, colors.$drc-medium-grey, colors.$drc-white, colors.$drc-medium-grey);
  }

  &.chip-purple {
    @include chip-theme(colors.$drc-white, colors.$drc-white, colors.$drc-purple, colors.$drc-white, colors.$drc-purple);
  }

  &.chip-gold {
    @include chip-theme(colors.$drc-black, colors.$drc-white, colors.$drc-insight-gold, colors.$drc-white, colors.$drc-insight-gold);
  }

}
