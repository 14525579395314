@use '@angular/material' as mat;


$sans-serif:  'Source Sans Pro, Helvetica, Verdana, sans-serif';

// see https://material.angular.io/guide/typography
$custom-typography: mat.m2-define-typography-config(
  $font-family:   $sans-serif,

  // Large, one-off headers, usually at the top of the page.
  $headline-1:     mat.m2-define-typography-level(112px, 112px, 300),
  $headline-2:     mat.m2-define-typography-level(56px, 56px, 400),
  $headline-3:     mat.m2-define-typography-level(45px, 48px, 400),
  $headline-4:     mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5:     mat.m2-define-typography-level(28px, 42px, 600),    // h1
  $headline-6:     mat.m2-define-typography-level(24px, 36px, 600),    // h2
  $subtitle-1:     mat.m2-define-typography-level(22px, 33px, 600),    // h3
  $subtitle-2:     mat.m2-define-typography-level(16px, 24px, 600),    // h4
  $body-1:         mat.m2-define-typography-level(16px, 30px, 600),    // Base body text
  $body-2:         mat.m2-define-typography-level(16px, 20px, 400),    // Secondary body text.
  $caption:        mat.m2-define-typography-level(16px, 20px, 400),    // Smaller body and hint text.
  $button:         mat.m2-define-typography-level(16px, 40px, 400),    // Buttons and anchors.
);

//@include mat.all-component-typographies($custom-typography)
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();

// Set a global default to use Rounded icons
mat-icon.material-icons {
  font-family: Material Icons Round, Material Icons;
  vertical-align: bottom;
}
