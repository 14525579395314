@use "colors";

.mat-mdc-tooltip.mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 16px;
  --mdc-plain-tooltip-supporting-text-line-height: 24px;
  --mdc-plain-tooltip-supporting-text-weight: 600;
  --mdc-plain-tooltip-container-color: var(--drc-black);
  --mdc-plain-tooltip-supporting-text-color: var(--drc-white);

  &.eca-light {
    --mdc-plain-tooltip-container-color: var(--drc-white);
    --mdc-plain-tooltip-supporting-text-color: var(--drc-black);
  }
}


.mat-mdc-tooltip-panel {
  &-right {
    .eca-input {
      margin-left: 24px;
    }
  }
  &-left {
    .eca-input {
      margin-right: 24px;
    }
  }
}
