@use 'colors';

$table-border: colors.$drc-black;

.eca-wrap-mat-table {
  width: 100%;
  overflow: auto;
  border: 1px solid var(--drc-black);
}

.mat-mdc-row {
  &.cdk-drag-preview, &.eca-reorder-directive-activated {
    border: 3px solid colors.$drc-blue;
  }
  &.cdk-drag-placeholder {
    border-top: 3px solid colors.$drc-blue;
    border-bottom: 3px solid colors.$drc-blue;
  }
}

.mat-mdc-table, mat-table {

  &.mdc-data-table__table {
    border: 1px solid $table-border;
    border-radius: 2px;
  }

  mat-row.mat-mdc-row, mat-header-row.mat-mdc-header-row {
    border-bottom: 1px solid $table-border;

    mat-cell.mat-mdc-cell, mat-header-cell.mat-mdc-header-cell {
      border: none;
    }
  }

  .mat-mdc-header-row {
    height: 54px;
    min-height: 53px; // 1px less to account for border and box-sizing
    background-color: colors.$drc-light-grey;
  }

  .mat-mdc-header-cell {
    font-weight: 600;
    color: colors.$drc-black;
    font-size: 16px;

    &:not(:first-of-type) {
      padding-left: 8px;
    }
    &:not(:last-of-type) {
      padding-right: 8px;
    }

    &[aria-sort='none'] {
      .mat-sort-header-container::after {
        position: absolute;
        top: 2px;
        right: -3px;

        font-family: "Material Icons Round";
        font-size: 18px;
        content: "\e5d7";

        opacity: 80%;
        transform: translateY(0);
        transition: 200ms;
      }

      &:hover, &.cdk-keyboard-focused {
        .mat-sort-header-container::after {
          opacity: 0 !important;
          transform: translateY(-25%) !important;
          transition: 200ms;
        }
      }
    }
  }

  .mat-mdc-row {
    min-height: 63px;

    &:nth-child(odd) {
      background-color: colors.$drc-light-blue;
      &:hover {
        background-color: colors.$drc-light-blue;
      }

      button.mat-mdc-button.eca-icon-only {
        background-color: colors.$drc-light-blue;

        &:hover:not([disabled]), &.focus-visible:not([disabled]), &:focus:not([disabled])::before {
          background-color: colors.$drc-white;
        }
        &[disabled] {
          background-color: var(--drc-light-grey);
        }
      }
    }

    .mat-mdc-cell {
      // ensure content larger than 64px still has some padding
      min-height: auto;
      align-self: stretch; // fill the height
      padding-top: 8px;
      padding-bottom: 8px;

      &:not(:first-of-type) {
        padding-left: 8px;
      }
      &:not(:last-of-type) {
        padding-right: 8px;
      }
    }
  }

  .mat-mdc-cell {
    a {
      // need space for the hover/focus outline to show
      margin: 4px;
      font-weight: 600;
    }
  }

  .mat-mdc-cell, .mat-mdc-header-cell {
    border-bottom-color: $table-border;
  }

  // For fixed (aka. sticky) columns or header
  .mat-mdc-table-sticky {

    // if the header row is sticky
    &.mat-mdc-header-row.mat-mdc-table-sticky-border-elem-top {
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    }

    // if left columns are sticky
    &.mat-mdc-header-cell, &.mat-mdc-cell {
      &.mat-mdc-table-sticky-border-elem-left {
        box-shadow: 4px 0 4px 0 rgba(0, 0, 0, 0.25);
        margin-right: 16px;
      }
    }
  }

  .highlight {
    background-color: colors.$drc-yellow;
  }

  tbody tr:last-child .mat-mdc-cell,
  .mat-mdc-row:last-child{
    border-bottom: 0;
  }
}
