@use './include-media' as eca;

$sizes:
  'xs' '<' 'sm',
  'sm' '>=' 'md',
  'md' '>=' 'lg',
  'lg' '>=' 'xl',
  'xl' '>=' 'xxl',
  'xxl' '>=',
  'lt-sm' '<' 'sm',
  'lt-md' '<' 'md',
  'lt-lg' '<' 'lg',
  'lt-xl' '<' 'xl',
  'lt-xxl' '<' 'xxl',
  'gt-xs' '>' 'sm',
  'gt-sm' '>' 'md',
  'gt-md' '>' 'lg',
  'gt-lg' '>' 'xl',
  'gt-xl' '>' 'xxl';


@mixin responsiveRules($className, $rules) {

  // define the styling rules for the passed className
  .#{$className} {
    @each $prop, $value in $rules {
      #{$prop}: #{$value};
    }
  }

  // loop through each responsive size and define the same styling rules for the className
  // use media queries via include-media to target specific resolutions
  @each $size, $operator, $bound in $sizes {
    .#{$size}\:#{$className} {

      @if $operator == '<' {
        @include eca.media('<#{$bound}') {
          @each $prop, $value in $rules {
            #{$prop}: #{$value} !important;
          }
        }
      } @else if ($operator == '>=' and not $bound) {
        @include eca.media('>=#{$size}') {
          @each $prop, $value in $rules {
            #{$prop}: #{$value} !important;
          }
        }
      } @else if ($operator == '>') {
        @include eca.media('>#{$bound}') {
          @each $prop, $value in $rules {
            #{$prop}: #{$value} !important;
          }
        }
      } @else {
        @include eca.media('>=#{$size}', '<#{$bound}') {
          @each $prop, $value in $rules {
            #{$prop}: #{$value} !important;
          }
        }
      }
    }
  }
}

// flex-direction - Sets the direction of flexible items.
// the 3 lines below will generate styling rules for:
// .eca-fx-row, .xs:eca-fx-row, .sm:eca-fx-row ... .xxl:eca-fx-row
$class: 'eca-fx-row';
$rules: ("display": "flex", "flex-direction": "row");
@include responsiveRules($class, $rules);

$class: 'eca-fx-row-reverse';
$rules: ("display": "flex", "flex-direction": "row-reverse");
@include responsiveRules($class, $rules);

$class: 'eca-fx-col';
$rules: ("display": "flex", "flex-direction": "column");
@include responsiveRules($class, $rules);

$class: 'eca-fx-col-reverse';
$rules: ("display": "flex", "flex-direction": "column-reverse");
@include responsiveRules($class, $rules);


// Defines flex-grow, flex-shrink and flex-basis.
$class: 'eca-fx-1';
$rules: ('flex': '1 1 0%', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-auto';
$rules: ('flex': '1 1 auto', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-initial';
$rules: ('flex': '0 1 auto', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-none';
$rules: ('flex': 'none', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-grow';
$rules: ('flex': '1 0 auto', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-grow-0';
$rules: ('flex-grow': '0', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-grow-1';
$rules: ('flex-grow': '1', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-shrink-0';
$rules: ('flex-shrink': '0', 'display': 'flex');
@include responsiveRules($class, $rules);

$class: 'eca-fx-shrink-1';
$rules: ('flex-shrink': '1', 'display': 'flex');
@include responsiveRules($class, $rules);

// flex-wrap - Defines whether the flexible items should wrap or not.
$class: 'eca-fx-wrap';
$rules: ('flex-wrap': 'wrap');
@include responsiveRules($class, $rules);

$class: 'eca-fx-wrap-reverse';
$rules: ('flex-wrap': 'wrap-reverse');
@include responsiveRules($class, $rules);

$class: 'eca-fx-nowrap';
$rules: ('flex-wrap': 'nowrap');
@include responsiveRules($class, $rules);

// justify-content - Defines the alignment on the main axis.
// Notably justify-items and justify-self are omitted at this point - we could add classes for those
// but trying to keep the number of classes to a minimum while also meeting requirements
$class: 'eca-fx-justify-content-start';
$rules: ('justify-content': 'flex-start');
@include responsiveRules($class, $rules);

$class: 'eca-fx-justify-content-center';
$rules: ('justify-content': 'center');
@include responsiveRules($class, $rules);

$class: 'eca-fx-justify-content-end';
$rules: ('justify-content': 'flex-end');
@include responsiveRules($class, $rules);

$class: 'eca-fx-justify-content-around';
$rules: ('justify-content': 'space-around');
@include responsiveRules($class, $rules);

$class: 'eca-fx-justify-content-between';
$rules: ('justify-content': 'space-between');
@include responsiveRules($class, $rules);

$class: 'eca-fx-justify-content-evenly';
$rules: ('justify-content': 'space-evenly');
@include responsiveRules($class, $rules);

// align-content - Controls the distribution of flex lines between and around items.
$class: 'eca-fx-align-content-start';
$rules: ('align-content': 'flex-start');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-content-center';
$rules: ('align-content': 'center');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-content-end';
$rules: ('align-content': 'flex-end');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-content-around';
$rules: ('align-content': 'space-around');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-content-between';
$rules: ('align-content': 'space-between');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-content-evenly';
$rules: ('align-content': 'space-evenly');
@include responsiveRules($class, $rules);

// align-items - Defines the alignment on the cross axis.
$class: 'eca-fx-align-items-start';
$rules: ('align-items': 'flex-start');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-items-center';
$rules: ('align-items': 'center');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-items-end';
$rules: ('align-items': 'flex-end');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-items-stretch';
$rules: ('align-items': 'stretch');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-items-baseline';
$rules: ('align-items': 'baseline');
@include responsiveRules($class, $rules);


// align-self - Defines the alignment for a particular element on the cross axis.
$class: 'eca-fx-align-self-auto';
$rules: ('align-self': 'auto');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-self-start';
$rules: ('align-self': 'flex-start');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-self-center';
$rules: ('align-self': 'center');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-self-end';
$rules: ('align-self': 'flex-end');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-self-stretch';
$rules: ('align-self': 'stretch');
@include responsiveRules($class, $rules);

$class: 'eca-fx-align-self-baseline';
$rules: ('align-self': 'baseline');
@include responsiveRules($class, $rules);

// gap - Defines the size of the gap between the rows and columns.
$class: 'eca-fx-gap-0';
$rules: ('gap': '0');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-1';
$rules: ('gap': '8px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-2';
$rules: ('gap': '16px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-3';
$rules: ('gap': '24px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-4';
$rules: ('gap': '32px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-5';
$rules: ('gap': '40px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-gap-6';
$rules: ('gap': '48px');
@include responsiveRules($class, $rules);

// offset - Defines the size of left margin.
$class: 'eca-fx-offset-0';
$rules: ('margin-left': '0');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-1';
$rules: ('margin-left': '8px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-2';
$rules: ('margin-left': '16px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-3';
$rules: ('margin-left': '24px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-4';
$rules: ('margin-left': '32px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-5';
$rules: ('margin-left': '40px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-offset-6';
$rules: ('margin-left': '48px');
@include responsiveRules($class, $rules);

$class: 'eca-fx-show';
$rules: ('display': 'flex !important');
@include responsiveRules($class, $rules);

$class: 'eca-fx-hide';
$rules: ('display': 'none');
@include responsiveRules($class, $rules);
