@use 'form-theme';
@use 'colors';

/* Select Panel */
$form-autocomplete-panel-text-highlight-color: colors.$drc-white;
$form-autocomplete-panel-text-highlight-bg: colors.$drc-black;

.mat-mdc-autocomplete-panel {

  min-width: 160px !important;
  width: fit-content !important;
  margin-top: 8px;
  margin-left: -16px;

  .mat-mdc-option {
    font-size: 16px;

    .highlight {
      color: $form-autocomplete-panel-text-highlight-color;
      background-color: $form-autocomplete-panel-text-highlight-bg;
    }
  }

  @include form-theme.select-options-panel;
}

/* Autocomplete style overrides for presentAsSelect flag */
.hide-placeholder.mat-mdc-input-element::placeholder {
  color: transparent !important;
  -webkit-text-fill-color: transparent;
}

.mat-form-field-hide-placeholder .show-placeholder.mat-mdc-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42) !important;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.42);
  cursor: pointer;
}
