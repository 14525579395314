
.eca-filter-and-table-container {
  display: flex;

  .eca-wrap-mat-table {
    .mat-mdc-table.mdc-data-table__table {
      border: none;
    }
  }

  /* Adds a bottom border when the filter-panel is rendered
   * - Next sibling selector to ensures the bottom border is removed when filters are not rendered
   */
  .eca-filter-panel-form + .eca-wrap-mat-table {
    tbody tr:last-child .mat-mdc-cell,
    .mat-mdc-row:last-child {
      border-bottom: 1px solid var(--drc-black);
    }
  }

}

/* Left Filter panel
 *  - Contains left / top / bottom border
 *  - Table should handle the right border
 */
.eca-filter-panel-form {
  flex: 0 0 265px;
  padding: 1rem;
  overflow: auto;
  border-bottom: 1px solid var(--drc-black);
  border-left: 1px solid var(--drc-black);
  border-top: 1px solid var(--drc-black);

  & > div:first-child {
    margin-bottom: 1rem;
  }

  h3 {
    align-self: center;
    margin: 0;
  }

  .status-group {
    margin-bottom: 16px;
  }

  .mat-mdc-radio-group {
    margin-bottom: 1rem;
  }

  .mat-mdc-form-field {
    width: 100%;

    .mat-mdc-form-field-subscript-wrapper {
      // assumes there is no validations or hints to display
      height: 16px;
    }
  }
}


.eca-filter-panel-toggle {
  margin-bottom: 16px;
  margin-top: 32px;
}

.eca-sticky-columns-table-container {
  .eca-filter-panel-form {
    & > div:first-child {
      padding: 16px;
      margin-top: -16px;
      margin-left: -16px;
      margin-right: -16px;
      position: sticky;
      top: -16px;
      z-index: 2;
      background-color: var(--drc-white);
    }
  }
}
