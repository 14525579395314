@use 'colors';

ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}

ul, ol {
  font-size: 16px;
  line-height: 24px;

  margin-top: 0;
  margin-bottom: 32px;
  padding-left: 32px;

  display: block;

  li {
    color: colors.$drc-black;
    max-width: 80ch;
  }

  ul,ol {
    // unset the margin for nested lists
    margin-bottom: 0;
  }
}
