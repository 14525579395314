@use 'colors';
@use 'theme';

$breadcrumb-color: colors.$drc-black;
$breadcrumb-color-inverse: colors.$drc-white;
$breadcrumb-active-color: theme.$drc-link-color;

$breadcrumb-divider: 'arrow_forward';

$breadcrumb-item-padding: 4px;

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

ul, ol {
  &.breadcrumb {
    padding-left: 0;
  }
}

.breadcrumb-item {

  // The separator between breadcrumbs is the Material Icon 'arrow_forward'
  + .breadcrumb-item {
    padding-left: $breadcrumb-item-padding;

    &::before {
      font-family: 'Material Icons', monospace;
      line-height: 24px;
      font-size: 20px;
      display: inline-block; // Suppress underlining of the separator in modern browsers
      padding-right: $breadcrumb-item-padding;
      color: $breadcrumb-color;
      content: $breadcrumb-divider;
      vertical-align: bottom;
    }
  }

  &.active {
    color: $breadcrumb-active-color;
  }
}


.eca-monotron-breadcrumb, .drc-dark-grey-bg {

  .breadcrumb-item {
    color: $breadcrumb-color-inverse;

    &::before {
      color: $breadcrumb-color-inverse;
    }

    a {
      color: $breadcrumb-color-inverse;
    }
  }
}
