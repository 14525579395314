@use 'theme';
@use 'form-theme';
@use 'colors';
@use 'menu';

// UI design for styling and colors:
// https://www.figma.com/file/6G4ZajIR9uu1AxVGDbuFF4/Dropdowns?node-id=0%3A1

$form-select-border: colors.$drc-black;
$form-select-focus-hover-border: colors.$drc-black;
$form-select-border-radius: 4px;

$form-select-color: colors.$drc-black;
$form-select-hover-color: colors.$drc-black;
$form-select-disabled-color: colors.$drc-light-grey;

$form-select-bg: #FFFFFF;
$form-select-disabled-bg: #F0F0F0;
$form-select-hover-bg: colors.$drc-light-blue;

$form-select-arrow-border: colors.$drc-black;

.mat-mdc-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline,
.mat-mdc-form-field.mat-mdc-form-field-type-eca-multi-select.mat-form-field-appearance-outline {

  --mat-select-enabled-trigger-text-color: var(--drc-black);
  --mat-select-disabled-trigger-text-color: var(--drc-black);
  --mat-select-trigger-text-weight: 400;
  --mat-select-trigger-text-line-height: 24px;

  /* Positioning Hacks */
  .mat-mdc-select {
    position: relative;
    z-index: 1;
  }

  .mdc-notched-outline .mdc-notched-outline__notch {
    border-color: transparent;
  }

  .mat-mdc-select-trigger {
    height: 34px;
  }

  /* Hover states */
  &:not(.mat-form-field-disabled) {

    /* Hover background and border */
    .mat-mdc-form-field-flex:hover .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: $form-select-focus-hover-border;
        border-width: 3px;
        background-color: $form-select-hover-bg;
      }
      .mdc-notched-outline__notch {
        border-color: transparent;
      }
    }
  }

  /* Focused States*/
  &.mat-focused {
    .mdc-notched-outline {

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: $form-select-focus-hover-border;
        border-width: 3px;
        background-color: $form-select-hover-bg;
      }
    }
  }

  /* Disabled overrides */
  &.mat-mdc-form-field-disabled {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing {
        border-color: $form-select-disabled-bg;
        background-color: $form-select-disabled-bg;
      }
    }
  }

  /* Read-Only Overrides */
  &.eca-read-only {
    .mdc-notched-outline {
      .mdc-notched-outline__leading,
      .mdc-notched-outline__notch,
      .mdc-notched-outline__trailing {
        border-color: colors.$drc-white;
        background-color: colors.$drc-white;
      }
    }
    .mat-mdc-select-arrow {
      // for some reason `display: none` also hides the field value so make the caret white instead
      border-color: colors.$drc-white !important;
    }
  }

  .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {

    width: 6px;
    height: 6px;
    border-left: 0;
    border-bottom: 0;
    border-top: 2px solid $form-select-arrow-border;
    border-right: 2px solid $form-select-arrow-border;
    border-color: $form-select-arrow-border;
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    margin: 0;

    // hide the default arrow
    svg {
      display: none;
    }
  }
}

.eca-search-prefix {
  flex: 1 1 auto;

  &.mat-mdc-form-field {
    width: auto;
  }

  &.mat-mdc-form-field.mat-mdc-form-field-type-mat-select.mat-form-field-appearance-outline,
  &.mat-mdc-form-field.mat-mdc-form-field-type-eca-multi-select.mat-form-field-appearance-outline {
    .mat-mdc-form-field-infix {
      width: 200px;
      .mat-mdc-select-value {
        // default max-width is 0 from Material
        max-width: unset;
        padding-right: 8px;
      }
    }
  }
}

/* Select Panel */

.cdk-overlay-pane {

  .mat-mdc-select-panel.eca-multi-select-panel {
    max-height: 508px; // Allow 10 options
  }

  .mat-mdc-select-panel {
    max-width: 100%;

    @include form-theme.select-options-panel;
  }
}
